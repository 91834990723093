import { ICacheItemConfig, ISaasCommonCacheKeys } from "@aspen/model";
import { saasApi as api } from "./api";

export const SAAS_DATA_CACHES_KEYS: Record<ISaasCommonCacheKeys, ICacheItemConfig<any>> = {
  saasWalletInfo: {
    api: api.saas.customer.wallet,
    param: {},
    language: ""
  },
  saasInterestVentures: {
    api: api.saas.venture.interestProducts,
    param: {
      limit: 100
    },
    language: ""
  }
};
