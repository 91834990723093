export const SAAS_GA_EVENT_NAME = {
  login: {
    sendCode: "sendCode",
    loginBtnClick: "loginBtnCk" // 点击登录按钮时
  },
  product: {
    subscribe: "subscribe",
    redeem: "redeem",
    sell: "sell",
    subscribeSuccess: "subscribeSuccess",
    redeemSuccess: "redeemSuccess",
    sellSuccess: "sellSuccess",
    interested: "interested",
    changeTab: "changeTab"
  },
  investor: {
    changeTab: "changeTab",
    download: "download"
  }
};
