"use client";
import type { ComponentType } from "react";
import React, { Component } from "react";

import { sendAuthEmailCode, sendEmailCode, verifyEmailCode } from "@aspen/services";
import { message } from "../customMessage";
import { BEFORE_LOGIN_SENAD_EMAIL_PATH, i18nUtil, USER } from "@aspen/libs";

interface IProps {
  [key: string]: any; // 保留any，HOC组件，父组件传过来的props可能是任何类型
}

export interface EmailCodeBaseProps {
  sendMailBtn: string;
  getEmailCode: (businessType: number, email: string) => void;
  verifyEmailInputCode: (
    verifyCode: number | string,
    email?: string
  ) => Promise<{
    consecutiveErrors?: boolean;
    verifyStatus?: string | number;
    data?: { result?: string };
    code: number | string;
  }>;
  loading: boolean;
  countdown: number;
}

type IState = {
  countdown: number;
  consecutiveErrors: boolean;
  loading: boolean;
  verifyStatus: boolean | string;
  interval: NodeJS.Timer | null;
  businessType: number;
};

export function EmailCodeLayout<T extends EmailCodeBaseProps>(WrappedComponent: ComponentType<T>) {
  type IProps = Omit<T, keyof EmailCodeBaseProps>;

  class WithEmailCodeLayout extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        loading: false,
        countdown: 60, // 倒计时
        consecutiveErrors: false, // 是否连续错误超过5次
        verifyStatus: "", // 验证码验证结果
        interval: null,
        businessType: 0
      };
      this.getEmailCode = this.getEmailCode.bind(this);
      this.verifyEmailInputCode = this.verifyEmailInputCode.bind(this);
    }

    getEmailCode: (businessType: number, email: string) => void = async (businessType, email) => {
      const intl = i18nUtil.t();
      // 当用户点击发送验证码时，先保存用户邮箱，上报信息时使用
      if (email && !localStorage.getItem(USER)) {
        localStorage.setItem(USER, email);
      }

      this.setState({ loading: true, businessType });
      //登陆后可不传邮箱
      const params = {
        businessType,
        email
      };

      //登陆后调用需要身份验证的验证码
      const isLoginRoute = BEFORE_LOGIN_SENAD_EMAIL_PATH.some((e) => location.pathname.includes(e));
      const fetchMethod = isLoginRoute ? sendEmailCode : sendAuthEmailCode;

      fetchMethod(params)
        .then((res) => {
          if (res?.code == "0") {
            // 发送验证码60s之后才可以再次发送
            this.setState({
              interval: setInterval(() => {
                if (this.state.countdown <= 0) {
                  // @ts-ignore check @赵靖
                  clearInterval(this.state.interval);
                  this.setState({
                    countdown: 60
                  });
                } else {
                  this.setState({
                    countdown: this.state.countdown - 1
                  });
                }
              }, 1000)
            });
          } else {
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };
    // todo 参数为object传递
    // @ts-ignore
    verifyEmailInputCode: (
      verifyCode: string,
      email?: string
    ) => Promise<{ consecutiveErrors?: boolean; verifyStatus?: string | number }> = async (
      verifyCode: string,
      email?: string
    ) => {
      const intl = i18nUtil.t();
      const { businessType } = this.state;
      const params = {
        businessType,
        email: email ?? localStorage.getItem(USER),
        verifyCode
      };
      // @ts-ignore
      await verifyEmailCode(params).then((res) => {
        if (res?.code == "0") {
          // @ts-ignore
          clearInterval(this.state.interval);
          this.setState({
            verifyStatus: res?.code,
            countdown: 60
          });
          // 验证成功将结果返回给组件，做下一步处理
          // @ts-ignore
          return { verifyStatus: res?.code, ...res };
        }
        if (res?.msg == "email_code_verify_count_often") {
          // 错误后将consecutiveErrors 置为true, 返回给组件使用  > , 比如输入的验证码从input中清空
          this.setState({
            consecutiveErrors: true
          });
          const msg = intl["email_code_verify_count_often"];
          message.error(msg);
          return { consecutiveErrors: this.state.consecutiveErrors };
        }
        if (res?.msg == "email_code_not_send") {
          //验证码为发送 点击提交下一步
          // 错误后将consecutiveErrors 置为true, 返回给组件使用  > , 比如输入的验证码从input中清空
          this.setState({
            consecutiveErrors: true
          });
          const msg = intl["email_code_not_send"];
          message.error(msg);
          return { consecutiveErrors: this.state.consecutiveErrors };
        }
        if (res?.msg == "customer.email.code.incorrect") {
          // 输入错误，请重试  - 尽可尝试4次
          // const errorEmailCode: string = `${intl["profile.googlecode.bind.email.code.error"]}(${this.state.retry})`;
          const errorEmailCode = intl["profile.googlecode.bind.email.code.error"];
          message.error(errorEmailCode);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      });
    };

    render() {
      const intl = i18nUtil.t();
      const sendMailBtn =
        this.state.countdown == 60
          ? intl["register.sendverify"]
          : `${intl["register.resend"]}(${this.state.countdown})`;
      const componentProps = { ...this.props } as unknown as T;
      return (
        <>
          <WrappedComponent
            {...componentProps}
            getEmailCode={this.getEmailCode}
            countdown={this.state.countdown}
            sendMailBtn={sendMailBtn}
            verifyEmailInputCode={this.verifyEmailInputCode}
            loading={this.state.loading}
          />
        </>
      );
    }

    componentWillUnmount(): void {
      // @ts-ignore
      clearInterval(this.state.interval);
    }
  }

  return WithEmailCodeLayout;
}
