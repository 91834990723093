import UAParser from "ua-parser-js";
import { DEVICE_ID, RECAPTCHA_V2_KEY_CHECKBOX, RECAPTCHA_V3_KEY } from "../config";
import { cookieUtil } from "./cookieUtil";
import { isClient } from "./common";

/**
 * @description: 判断用户当前设备是否移动端
 * @params
 * @return boolean, 是否移动端
 */
export const isMobile: () => Boolean = () => {
  if (!isClient) {
    return false;
  }
  const parser = new UAParser(navigator.userAgent);
  const device = parser.getDevice();
  return device?.type === "mobile";
};

/**
 * @description: 判断用户当前设备是否是pad
 * @params
 * @return boolean, 是否移动端
 */
export const isPad: () => Boolean = () => {
  if (!isClient) {
    return false;
  }
  const parser = new UAParser(navigator.userAgent);
  const device = parser.getDevice();
  return device?.type === "tablet";
};

export const ONLY_MOBILE = isMobile() && !isPad();

/**
 * @description: 判断用户当前设备，返回对应的值
 * @params
 * @return string, webapp | pad | web
 */
export const getSource: () => string = () => {
  if (ONLY_MOBILE) {
    return "webapp";
  } else if (isPad()) {
    return "pad";
  } else {
    return "web";
  }
};

// recaptcha v3根据公钥生成token
export const grecaptchaToken: (action?: string) => Promise<string> = (action) => {
  return new Promise((resolve) => {
    // @ts-ignore
    window?.grecaptcha?.ready(() => {
      // @ts-ignore
      window.grecaptcha.execute(RECAPTCHA_V3_KEY, { action: action }).then((token) => {
        resolve(token);
      });
    });
  });
};

// 提供id
export const grecaptchaCheckbox: (html_element: string) => Promise<string> = (html_element) => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    window?.grecaptcha?.ready(() => {
      // @ts-ignore
      window.grecaptcha.render(html_element, {
        sitekey: RECAPTCHA_V2_KEY_CHECKBOX,
        theme: "dark",
        size: "normal", //尺寸规则，有normal与compact两个值可选
        callback: (token: any) => {
          // 接入后端校验
          resolve(token);
        },
        "expired-callback": () => {
          reject("expired");
        }, //验证过期回调
        "error-callback": () => {
          reject("error");
        } //验证错误回调
      });
    });
  });
};

/**
 * 获取设备唯一 ID，此方法必须在浏览器执行
 * @returns string
 */
export const getDeviceId = async () => {
  let deviceId = "aspen";
  if (!isClient) {
    return "aspen";
  }
  deviceId = localStorage.getItem(DEVICE_ID) || "";
  if (deviceId) {
    return deviceId;
  }
  if (isClient) {
    // 因为fingerprintjs调用了window 所以只能在客户端异步调用
    // @ts-ignore
    return import("@fingerprintjs/fingerprintjs")
      .then((FingerprintJS) => FingerprintJS.load())
      .then((fp) => fp.get())
      .then((res) => {
        deviceId = `aspen_${res.visitorId}`;
        localStorage.setItem(DEVICE_ID, deviceId);
        cookieUtil.set("deviceId", deviceId);
        console.info(deviceId);
        return deviceId;
      });
  }
};

/**
 * 请求上报客户设备信息
 * @params number
 */
export const reportGaInfo = async (operationType: number) => {
  // TODO
  // const deviceId = localStorage.getItem(DEVICE_ID) ?? (await getDeviceId());
  // const reportParams = {
  //   adId: deviceId,
  //   deviceId: deviceId,
  //   deviceType: "web",
  //   networkType: "",
  //   operationType: operationType
  // };
  // reportDeviceInfo(reportParams);
};
