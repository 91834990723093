// 判断当前用户是否有指定路由访问权限， 路由命名： /xxx
import {
  AGENT,
  AGREEMENT_PATH,
  CUSTOMER_ID,
  DOMAIN_PREFIX_PORTAL,
  DOWNLOAD_PATH,
  HOME_PATH,
  MANAGER_PATH,
  PARTNER_PATH,
  IRole,
  IS_AUTHENTICATED,
  LOGIN_PATHS,
  LOGIN_REDIRECT,
  POLICY_PATH,
  REDIRECT_ID,
  USER_AUTH,
  USER_ROLE,
  USER_ROLE_POWER
} from "../config";
import { NextRouter } from "next/router";
import { i18nUtil } from "./i18n";
import { getLocalStorageTokenKey, getSource, isClient } from "./index";
import { NextApiRequest, NextApiResponse } from "next/types";
import { ISSRHeaders } from "@aspen/model";
import Bugsnag from "@bugsnag/js";

// protal系统时，踢出用户，下次用户登录重定向到被踢出时的页面
export const addLoginRedirect = () => {
  if (
    window.location.origin.includes(DOMAIN_PREFIX_PORTAL) ||
    process.env.NODE_ENV != "production"
  ) {
    if (window.location.pathname.includes(LOGIN_PATHS.LOGIN)) return;

    // 登录之后以下页面不需要重定向
    if ([DOWNLOAD_PATH, POLICY_PATH, AGREEMENT_PATH].includes(window.location.pathname)) {
      return;
    }

    const currentPath = window.location.pathname.replace("/zh", "") + window.location.search;
    const userId = window.localStorage.getItem(CUSTOMER_ID);
    currentPath && window.localStorage.setItem(LOGIN_REDIRECT, currentPath);
    userId && window.localStorage.setItem(REDIRECT_ID, userId);
  }
};

export const checkAuth: (routeConfig: IRole, currentRoute: string) => Boolean = (
  routeConfig,
  currentRoute
) => {
  // 如果routeConfig不错在当前路由 直接fasle
  if (!routeConfig?.[currentRoute]) return false;

  // 不需要登陆权限的，不判断登陆的auth
  if (!routeConfig?.[currentRoute]?.needAuthenticated) return true;

  //user_auth只存在一种角色
  const currentRole = (isClient && window?.localStorage?.getItem(USER_AUTH)) ?? "";
  // @ts-ignore
  if (routeConfig?.[currentRoute]?.role?.length > 0) {
    // @ts-ignore
    return routeConfig?.[currentRoute]?.role?.includes(currentRole) ? true : false;
  }

  if (
    routeConfig?.[currentRoute]?.role?.length == 0 ||
    !routeConfig?.[currentRoute]?.needAuthenticated ||
    !routeConfig?.[currentRoute]?.role
  ) {
    return true;
  }
  if (!isClient) return true;
  return false;
};

export const checkRouterAuth = (routeConfig: IRole, router: NextRouter, toDefaultPath: string) => {
  const toLogoutPath = LOGIN_PATHS.LOGIN; // getCurrentLoginPath();
  const user_auth = localStorage.getItem(USER_AUTH) ?? "";
  let asPath = router.asPath;
  let pathname = checkPathName(router.pathname != "/_error" ? router.pathname : asPath);
  const isLogin: boolean | string = checkIfLogin();
  const targetRouterConfig = routeConfig?.[pathname];
  // 如果路由不存在跳转至home
  if (pathname === "/" || !pathname || !targetRouterConfig || pathname == "_error") {
    router.replace(toDefaultPath);
    return null;
  }
  if (isLogin) {
    // 判断当前角色是否有访问当前路由的权限
    if (checkAuth(routeConfig, pathname)) {
      // router.replace(asPath);
      return null;
    } else {
      // TODO
      // message.error(intl["global.access.denied"]);
      //登陆状态下 user_auth被修改，如果权限存在跳入默认登陆后页面，不存在跳入login页面
      if (USER_ROLE_POWER[user_auth]) {
        router.replace(toDefaultPath);
      } else {
        router.replace(toLogoutPath);
      }
      return null;
    }
  } else {
    if (targetRouterConfig?.needAuthenticated) {
      addLoginRedirect();
      router.replace(toLogoutPath);
    } else {
      router.replace(asPath);
    }
    return null;
  }
};

export const checkIfLogin: () => boolean | string = () => {
  if (isClient && location.pathname.includes("login")) return false;
  const _isLogin =
    (isClient && localStorage.getItem(IS_AUTHENTICATED) && localStorage.getItem(USER_AUTH)) ||
    false;
  return _isLogin;
};

/**
 * @description ssr 设置headers参数, 并设置header缓存参数
 * @param req NextApiRequest
 * @param res NextApiResponse
 * @return IResHeaders headers
 */
export const ssrSetHeaders: (
  req: NextApiRequest,
  res: NextApiResponse,
  locale: string
) => ISSRHeaders = (req, res, locale) => {
  // ssr上报需要重新设置用户Id和email
  Bugsnag.setUser(req.cookies?.customerId, req.cookies?.user, req.cookies?.user);

  // 缓存处理：10s内重复请求缓存不更新，10s后重复请求，则第59s后在后台重新验证请求，刷新页面才能看到新值
  // https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props#caching-with-server-side-rendering-ssr

  // TODO: 由于存在切换账号 导致新账号 ssr涉及到用户的数据为上一个账号的数据，先注释此处
  // res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");
  // @ts-ignore
  const jwtTokenName = getLocalStorageTokenKey(encodeURIComponent(req.headers.host));
  // @ts-ignore
  const operatingTokenName = getLocalStorageTokenKey(encodeURIComponent(req.headers.host), AGENT);

  const headers: ISSRHeaders = {
    jwtToken: req.cookies?.[jwtTokenName] ?? "",
    language: locale,
    // @ts-ignore
    domain: req.headers.host,
    source: getSource(),
    device_id: req.cookies?.deviceId ?? ""
  };
  const isKXFKSDKDS = process?.env?.NEXT_PUBLIC_REFSFGADEF ?? "";

  if (process.env.NODE_ENV != "production" || isKXFKSDKDS) {
    headers.REFSFGADEF = "KXFKSDKDS";
  }

  const userAuth = req.cookies?.[USER_AUTH] ?? "";
  const agentOperatingToken = req.cookies?.[operatingTokenName] ?? "";

  if (userAuth === USER_ROLE.ROLE_PARTNER_CUSTOMER) {
    headers.agentOperatingToken = agentOperatingToken;
  } else if (
    userAuth === USER_ROLE.ROLE_MANAGER_CUSTOMER ||
    userAuth === USER_ROLE.ROLE_MANAGER_READONLY
  ) {
    headers.customerManagerOperatingToken = agentOperatingToken;
  }

  return headers;
};

export const checkPathName: (_pathname: string) => string = (_pathname) => {
  let pathname = _pathname;
  if (pathname?.includes("/[")) {
    pathname = pathname.split("/[")[0];
  }
  return pathname;
};
