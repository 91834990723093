import { INVESTOR_PORTAL_TYPE, IPagerInfo } from "@aspen/model";

// saas BusinessType
export enum SaasBusinessType {
  emailCode = 14
}

export const SAAS_VENTURE_TAB = {
  primary: "primary",
  secondary: "secondary",
  favorites: "favorites"
};

export const SAAS_INVESTMENT_TAB = {
  venture: "venture",
  strategy: "strategy"
};

export const SAAS_INVESTOR_TAB = {
  updates: "updates",
  news: "news",
  research: "research",
  expert: "expert",
  otherExperts: "otherExperts",
  report: "report"
};

export const SAAS_INVESTOR_PARAMS_INFORMATION_TYPE: { [key: string]: INVESTOR_PORTAL_TYPE } = {
  updates: "UPDATES",
  news: "NEWS",
  research: "RESEARCH",
  expert: "EXPERTS",
  otherExperts: "EXPERTS",
  report: "REPORTS"
};

export const SAAS_INVESTOR_PARAMS_TYPE: {
  [key: string]:
    | "NEWS"
    | "STORY"
    | "NEWS_FLASH"
    | "NOTICE"
    | "BRIEF"
    | "REPORT"
    | "MONTHLY_REVIEW"
    | "WEEKLY_REPORT"
    | "EXPERT_POST";
} = {
  NEWS: "STORY",
  RESEARCH: "REPORT",
  EXPERTS: "EXPERT_POST"
};

export const SAAS_INVESTOR_PARAMS_ACTION: { [key: string]: "ALL" | "RECOMMEND" } = {
  ALL: "ALL",
  RECOMMEND: "RECOMMEND"
};

export const SAAS_INVESTOR_PARAMS_EXPERT_TYPE: { [key: string]: "ALL" | "CHOSEN" | "FOLLOWED" } = {
  ALL: "ALL",
  CHOSEN: "CHOSEN",
  FOLLOWED: "FOLLOWED"
};

export enum SAAS_VENTURE_SHARES {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY"
}

export const SAAS_VENTURE_DETAIL_TAB = {
  detail: "detail",
  update: "update"
};

export const SAAS_INIT_PAGE_INFO: IPagerInfo = { page: 1, nextToken: "" };
export const SAAS_DEFAULT_LIMIT = 9;
export const SAAS_DEFAULT_TABLE_LIMIT = 10;
export const SAAS_DEFAULT_INVESTOR_LIMIT = 15;

export const SAAS_VENTURE_ASSET_TYPE = {
  Token: 0,
  Equity: 1,
  Fund: 2,
  NFT: 3
};

export const venture_investment_column = ["date", "venture", "stage", "type", "amount"];
export const venture_investment_dataIndex = {
  date: "operatorTime",
  venture: "ventureName",
  stage: "shares",
  type: "type",
  amount: "amount"
};
export const strategy_investment_column = ["date", "strategy", "type", "amount"];
export const strategy_investment_dataIndex = {
  date: "operatorTime",
  strategy: "strategyName",
  type: "type",
  amount: "amount"
};

export const venture_wallet_column = [
  "venture",
  "stage",
  "changeAmount",
  "changePercent",
  "fairValue",
  "control"
];
export const venture_wallet_dataIndex = {
  venture: "ventureName",
  stage: "shares",
  changeAmount: "changeAmount",
  changePercent: "changePercent",
  fairValue: "fairValue",
  control: "control"
};
export const strategy_wallet_column = [
  "strategy",
  "type",
  "changeAmount",
  "changePercent",
  "fairValue",
  "control"
];
export const strategy_wallet_dataIndex = {
  strategy: "strategyName",
  type: "strategyType",
  changeAmount: "changeAmount",
  changePercent: "changePercent",
  fairValue: "fairValue",
  control: "control"
};

// saas公用接口和参数一致的请求，可以在这里配置
export const SAAS_COMMON_CACHE_PRIORITY_KEYS = {
  saasWalletInfo: "saasWalletInfo",
  saasInterestVentures: "saasInterestVentures"
} as const;

// TODO， 移到saas的颜色配置中 @于衍
// 钱包饼图颜色配置
export const productColor1 = {
  type: "linear",
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: "#B81FFD" // 0% 处的颜色
    },
    {
      offset: 1,
      color: "#DA22FF" // 100% 处的颜色
    }
  ],
  global: false // 缺省为 false
};
export const productColor2 = {
  type: "linear",
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: "#4EFCE1" // 0% 处的颜色
    },
    {
      offset: 1,
      color: "#4EAFFB" // 100% 处的颜色
    }
  ],
  global: false // 缺省为 false
};
