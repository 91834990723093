"use client";
import React, { Component } from "react";
import type { ICurrency } from "@aspen/model/index";
import { updateCoinList } from "@aspen/store";
import { fetchCoinList } from "@aspen/services/index";
import { connect } from "react-redux";
import { isEmpty } from "lodash-es";
import { convertUSD2USDC } from "@aspen/libs/index";

interface IProps {
  coinList: ICurrency[];
  updateCoinList: (data: { coinList: ICurrency }) => void;
  [key: string]: any; // 保留any，HOC组件，父组件传过来的props可能是任何类型
}

const mapStateToProps = (state) => {
  const { coinList } = state.currencies;
  return {
    coinList // 所有币种、名称、icon等
  };
};
const mapDispatchToProps = () => {
  return {
    updateCoinList: (payload) => updateCoinList(payload)
  };
};

export const WithCoinList = (WrappedComponent) => {
  class WithCoinList extends Component<IProps> {
    fetchAllCoinList: () => void = async () => {
      fetchCoinList()
        .then((res) => {
          if (res?.code == "0" && !isEmpty(res?.data)) {
            this.props.updateCoinList({ coinList: res?.data });
          } else {
            // ignore
          }
        })
        .finally(() => {});
    };

    getCoinFullName: (coinCode: string) => string = (coinCode) => {
      const fullName = convertUSD2USDC(this.props?.coinList[coinCode]?.name) || "";
      return fullName;
    };

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            fetchAllCoinList={this.fetchAllCoinList}
            coinList={this.props.coinList}
            getCoinFullName={this.getCoinFullName}
          />
        </>
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps())(WithCoinList);
};
