"use client";
import { Spin } from "antd";
import React, { RefObject, useEffect, useRef, useState } from "react";

type IProps = {
  htmlContent: string;
};

function HtmlContent(props: IProps) {
  const htmlRef = useRef<HTMLBodyElement>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (htmlRef.current) {
      const scripts = htmlRef.current.getElementsByTagName("script");
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < scripts.length; i++) {
        // eslint-disable-next-line no-eval
        eval(scripts[i].innerHTML);
      }
      // setTimeout(() => {
      // }, 1000);
    }
    setLoading(false);
  }, [props.htmlContent]);

  return (
    <Spin spinning={loading}>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center"
        }}
        // @ts-ignore TODO， check @elaine
        ref={htmlRef}
        dangerouslySetInnerHTML={{ __html: props.htmlContent }}
      />
    </Spin>
  );
}

export const HtmlContentComponent = React.memo(HtmlContent);
