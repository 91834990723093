export const saasApi = {
  saas: {
    auth: {
      login: "auth/customer/login",
      getEmailCode: "auth/email/verification-code/{email}",
      logout: "auth/customer/logout"
    },
    tenant: {
      info: "customer/tenant/info-by-domain-name"
    },
    customer: {
      wallet: "customer/wallet/info",
      signature: "/customer/oss/url/signature"
    },
    venture: {
      subscription: "venture/subscription",
      redemption: "venture/redemption",
      records: "venture/records",
      products: "venture/products",
      productDetail: "venture/products/{id}",
      interest: "venture/interest",
      interestProducts: "/venture/interest-products"
    },
    strategy: {
      subscription: "strategy/subscription",
      redemption: "strategy/redemption",
      records: "strategy/records",
      products: "strategy/products",
      productDetail: "strategy/products/{id}"
    },
    investor: {
      list: "investor-portal/{informationType}",
      markRead: "investor-portal/{informationType}/mark-read",
      detail: "investor-portal/detail/{id}",
      experts: "investor-portal/experts",
      expertsDetail: "investor-portal/experts/{id}",
      productUpdates: "investor-portal/product-updates/{productId}",
      unread: "investor-portal/updates/unread"
    }
  }
};
