"use client";
import React from "react";
import {
  AUTHORIZATION_TYPE,
  CUSTOMER_ID,
  CUSTOMER_INFO,
  HOME_PATH,
  IS_AUTHENTICATED,
  IS_GOOGLE_Certified,
  KYC_CERTIFIED_LEVEL,
  KYC_CERTIFIED_STATUS,
  LOGIN_REDIRECT,
  MEMBER_INFO,
  OPERATE_CUSTOMER_EMAIL,
  REDIRECT_ID,
  USER,
  USER_AUTH,
  USER_ROLE,
  USER_ROLE_POWER,
  cookieUtil,
  getLocalStorageTokenKey
} from "@aspen/libs/index";
import { useRouter } from "next/router";

interface IProps {
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

export const WithHandleLogin = (WrappedComponent) => {
  const HandleLogin: React.FC<IProps> = (props: IProps) => {
    const router = useRouter();
    // 保存localStorage
    const setCustomerStorage = (resData) => {
      const {
        user_id,
        account_id,
        account_email, // 当前登录用户邮箱
        user_email, // 对子账号来说，是主账号邮箱
        jwtToken,
        isGoogleCertified,
        user_roles,
        customer_info,
        authorizationType,
        kycLevel, // L0 L1 L2
        kycStatus
      } = resData;

      // 子账号登陆时
      if (user_roles.join(",").toLocaleLowerCase() == USER_ROLE.ROLE_SUB) {
        localStorage.setItem(OPERATE_CUSTOMER_EMAIL, user_email);
      }
      localStorage.setItem(USER_AUTH, user_roles ? user_roles.join(",").toLocaleLowerCase() : "");
      localStorage.setItem(AUTHORIZATION_TYPE, authorizationType);
      localStorage.setItem(getLocalStorageTokenKey(location.origin), jwtToken ?? "");
      cookieUtil.set(getLocalStorageTokenKey(location.host), jwtToken ?? "");
      localStorage.setItem(IS_AUTHENTICATED, "true");
      localStorage.setItem(CUSTOMER_ID, account_id || user_id);
      localStorage.setItem(USER, account_email);
      cookieUtil.set(USER, account_email);
      cookieUtil.set(CUSTOMER_ID, account_id || user_id);

      localStorage.setItem(CUSTOMER_INFO, customer_info);
      localStorage.setItem(IS_GOOGLE_Certified, Number(isGoogleCertified).toString());
      localStorage.setItem(KYC_CERTIFIED_LEVEL, kycLevel);
      localStorage.setItem(KYC_CERTIFIED_STATUS, kycStatus);
    };

    // 保存会员信息
    const saveMemberInfo = (customer_info) => {
      const customerInfo = customer_info ? JSON.parse(customer_info) : {};
      const { vipEndTime, vipLevel } = customerInfo;
      let memberInfo = { vipEndTime, vipLevel };
      if (vipEndTime && !vipLevel) {
        // 如果level为null 默认置为L0
        memberInfo.vipLevel = "L0";
      }
      localStorage.setItem(MEMBER_INFO, JSON.stringify(memberInfo));
    };

    // 登录之后重定向
    const gotoRedirectPath = (resData) => {
      const { user_id, user_roles } = resData;
      let toPath =
        (user_roles && USER_ROLE_POWER[user_roles.join().toLocaleLowerCase()]?.loginToPath) ||
        HOME_PATH;

      // 用户被踢出或者未登录输入地址栏链接跳回login页面，登录后跳转到之前的路由
      const lastPath = localStorage.getItem(LOGIN_REDIRECT);
      const lastId = localStorage.getItem(REDIRECT_ID);
      //1:用户从未登录，跳转链接跳出后不存在lastId ；2: 用户登录后超时被踢出，存在lastId，当前登录的账号与上次登录的账号一致时，跳转到踢出时的页面
      if (lastPath && (!lastId || user_id === lastId)) {
        toPath = lastPath.replace("/zh", "");
      }

      router.push(toPath);
    };
    return (
      <WrappedComponent
        {...props}
        saveMemberInfo={saveMemberInfo}
        setCustomerStorage={setCustomerStorage}
        gotoRedirectPath={gotoRedirectPath}
      />
    );
  };

  return HandleLogin;
};
