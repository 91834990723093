"use clent";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";

// @ts-ignore
import { message } from "../aspenUI/index";
import Bugsnag from "@bugsnag/js";
import { addPendingRequest, removePendingRequest } from "./adapterTools";
import {
  AGENT,
  DEVICE_ID,
  DOMAIN_GREEN,
  DOMAIN_GREEN_PARTNER,
  EN,
  ERROR,
  ERROR_CODE,
  ERROR_MSG,
  LANGUAGE,
  NOT_LOGIN_CODE,
  PROFILE_PATHS,
  USER_AUTH,
  USER_ROLE,
  WALLET_PATHS,
  host,
  operationServer,
  LOGIN_PATHS,
  isClient
} from "@aspen/libs/index";
import {
  addLoginRedirect,
  cookieUtil,
  formatBugsnagMessage,
  getCookie,
  getLocalStorageTokenKey,
  getSource,
  i18nUtil,
  ONLY_MOBILE,
  sendToApp,
  convertJsonToString
} from "@aspen/libs/index";
import { aspenApi as api } from "./aspen/api";
import Router from "next/router";

let netErrorList: string[] = [];
const errorSize = 10;
const cancelTokenSource = axios.CancelToken.source();

axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers["Accept-Aspen-Language"] = "aspen_en";
const isKXFKSDKDS = process?.env?.NEXT_PUBLIC_REFSFGADEF ?? "";
let _baseURL = process.env.NODE_ENV != "production" || isKXFKSDKDS ? host.dev : host.prod;

const service: AxiosInstance = axios.create({
  // 非生产环境使用ip+port+魔法header访问，允许跨域
  baseURL: _baseURL,
  timeout: 2 * 60 * 1000
});

const uploadNetWorkError = (err: any) => {
  let message = formatBugsnagMessage(convertJsonToString(err));
  //简单的网络错误不要重复上传
  if (message.includes("Network Error") || message.includes("timeout of")) {
    const re = new RegExp("\\[XHR\\]--path: .+?(?=--Error)", "gims");
    const simpleError = message.match(re)?.[0];
    //将简化版网络错误放入栈中
    simpleError && netErrorList.push(simpleError);
    if (netErrorList.length >= errorSize) {
      //上传错误
      //错误栈满了,重置错误栈
      Bugsnag.notify(new Error(netErrorList.join(" ; \n")));
      netErrorList = [];
    }
    return;
  }
  Bugsnag.notify(new Error(message));
};

const _checkAndhandleReqHeaders: (config: AxiosRequestConfig) => AxiosRequestConfig = (config) => {
  // 处理post请求的参数
  if (
    config.method &&
    config.method.toLowerCase() == "post" &&
    config.headers &&
    config.headers["Content-Type"] == "application/x-www-form-urlencoded"
  ) {
    config.data = qs.stringify(config.data);
  }
  // 配置蓝绿请求头参数
  if (
    (isClient &&
      (location.href.includes(DOMAIN_GREEN) || location.href.includes(DOMAIN_GREEN_PARTNER))) ||
    process.env.NEXT_PUBLIC_COLOR_ENV == "green"
  ) {
    if (config.headers) config.headers["traffic"] = "green";
  }

  if (isClient && typeof localStorage !== "undefined" && config.headers) {
    const jwtToken = window.localStorage.getItem(getLocalStorageTokenKey(location.origin)) ?? "";
    // 设置请求头jwtToken
    // 注册之后绑定手机操作，获取验证码的时候，调用接口需要jwtToken，在业务场景出单独传参headers
    config.headers.jwtToken = config.headers?.jwtToken ?? jwtToken;

    // xsrf安全认证
    const xsrfToken = getCookie("XSRF-TOKEN") ?? "";
    config.headers["X-XSRF-TOKEN"] = xsrfToken;

    // 上报设备id
    const deviceId = localStorage.getItem(DEVICE_ID) ?? "";
    config.headers["device_id"] = deviceId;

    // 请求头添加语言
    config.headers["language"] = window.localStorage.getItem(LANGUAGE) ?? EN;

    // 请求头添加source
    config.headers.source = getSource();

    // 请求头添加agentOperatingToken/customerManagerOperatingToken
    const agentToken = getLocalStorageTokenKey(location.origin, AGENT);
    if (window.localStorage.getItem(agentToken)) {
      const agentOperatingToken = window.localStorage.getItem(agentToken) ?? "";
      if (window.localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_PARTNER_CUSTOMER) {
        config.headers.agentOperatingToken = agentOperatingToken;
      } else if (
        window.localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_MANAGER_CUSTOMER ||
        window.localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_MANAGER_READONLY
      ) {
        config.headers.customerManagerOperatingToken = agentOperatingToken;
      }
    }

    // manager 只读权限时，不可进行其它操作，只能查询用户信息
    const intl = i18nUtil.t();
    if (window.localStorage.getItem(USER_AUTH)) {
      if (
        config.method &&
        config.method.toLowerCase() == "post" &&
        window.localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_MANAGER_READONLY &&
        !location.href.includes(WALLET_PATHS.WALLET_ADDACCOUNT) &&
        !location.href.includes(PROFILE_PATHS.PROFILE_KYC_VERIFICATION)
      ) {
        // 取消请求 提示用户无权限
        const errorMsg = { message: intl["client.request.is.not.authorized"] };
        config.cancelToken = cancelTokenSource.token;
        cancelTokenSource.token.reason = errorMsg;
        cancelTokenSource.cancel(intl["client.request.is.not.authorized"]);
      }
    }
  }
  return config;
};

// const checkIsNeedToBeCached: (config: AxiosRequestConfig) => ICachekeys = (config) => {
//   const _config = cloneDeep(config);
//   if (_config?.params) {
//     delete _config.params.method;
//     delete _config.params.url;
//   }
//   const _needToBeCached: ICachekeys = Object.keys(DATA_CACHES_KEYS).find((key) => {
//     if (
//       DATA_CACHES_KEYS[key].api == _config.url &&
//       isEqual(_config.params, DATA_CACHES_KEYS[key].param)
//     ) {
//       return key;
//     }
//   });
//   return _needToBeCached;
// };
// const _saveDataToLocalStorage: (res: AxiosResponse) => void = (res) => {
//   const _url = res.config.url;
//   if (res?.config?.params) {
//     delete res.config.params.method;
//     delete res.config.params.url;
//   }
//   const _needToBeCached = checkIsNeedToBeCached(res?.config);
//   if (_needToBeCached) {
//     let _params = qs.stringify(res.config.params);
//     let _cacheKey = null;
//     if (DATA_CACHES_KEYS[_needToBeCached]?.language) {
//       const _language: ILanguageType =
//         (window.localStorage.getItem(LANGUAGE) as ILanguageType) ?? EN;
//       _cacheKey = generateDataCacheKey({
//         params: _params,
//         api: _url,
//         language: _language
//       });
//     } else {
//       _cacheKey = generateDataCacheKey({
//         params: _params,
//         api: _url
//       });
//     }
//     // 注意存入redux的数据格式和此保持一致, 因为取值未做针对性处理，数据存在时再储存数据防止接口报错带来的js问题
//     res?.data?.data && localStorage.setItem(_cacheKey, JSON.stringify(res?.data?.data));
//   }
// };
// 处理响应成功的上报场景
const _handleResNotify: (res: AxiosResponse) => void = (res) => {
  // operation请求取消bugsnag异常上报
  const isOperation = res?.config?.baseURL === operationServer;
  //  下载月结账单接口，res?.data是ArrayBuffer，不需要上报
  const isBuffer = res?.data instanceof ArrayBuffer;
  // ERROR_CODE 排除错误上报的code集合  ERROR_MSG 错误上报的信息集合
  const isErrorNoneValid =
    isOperation ||
    isBuffer ||
    res?.data?.code == "0" ||
    ERROR_CODE[res?.data?.code] == String(res?.data?.msg);
  const isErrorMsg = ERROR_MSG.some((msg) => res?.data?.msg?.includes(msg));
  if (!isErrorNoneValid || isErrorMsg) {
    uploadNetWorkError(res);
  }
};

// @ts-ignore
const _handleErrorRes: (error: AxiosError) => Promise<any> = (error) => {
  const { response } = error;
  const intl = i18nUtil.t();
  const errorMsgTips = intl["global.error.tip"];
  if (response) {
    // 上报请求错误
    uploadNetWorkError(response);

    const { error, status, code, message: errMessage, path } = response.data as any;
    let errMsg = errorMsgTips;
    if (error || code) {
      errMsg = `${error ?? code}: ${status || intl[errMessage] || errMessage} for ${path}`;
    }
    // 临时方案：阿里无痕验证请求头太大导致400，请求返回400并且是登陆接口时不弹错误
    if (!(response?.status == 400 && response.request.responseURL.includes(api.customer.login))) {
      message.error(errMsg);
    }
    return Promise.reject({ msg: errMsg });
  } else {
    // 没有返回错误或者请求取消不上报
    if (
      !error ||
      Object.getPrototypeOf(error).__CANCEL__ ||
      (error && error?.message?.includes("Request aborted"))
    )
      return;

    // 上报请求错误
    uploadNetWorkError(error);

    const errmsg = intl?.[error?.message] || error?.message || errorMsgTips;
    message.error(errmsg);
    return Promise.reject({ msg: errmsg, error });
  }
};

// 请求拦截器
service.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    // 把当前请求信息添加到pendingRequest对象中
    addPendingRequest(config);
    // 本地或者配置了NEXT_PUBLIC_HOST_REFSFGADEF=KXFKSDKDS的环境，添加魔法header请求头 FIXME: 因为后端prod 401问题 目前全部打开此headers
    // if (process.env.NODE_ENV != "production" || isKXFKSDKDS) {
    if (config.headers) config.headers.REFSFGADEF = "KXFKSDKDS";
    // }
    return _checkAndhandleReqHeaders(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  // 请求成功
  (res: AxiosResponse) => {
    // 未登陆 , -1: 客户未登陆 -3: 代理操作超时
    if (NOT_LOGIN_CODE.includes(+res?.data?.code)) {
      if (ONLY_MOBILE) {
        //app传入的身份信息过期,需要app端登录
        sendToApp({ type: "notLogin" });
      }

      const _domain = res?.config?.headers?.domain ?? "";
      _domain && cookieUtil.remove(getLocalStorageTokenKey(_domain));

      if (isClient && !window.location.href.includes("login")) {
        window.localStorage.setItem(ERROR, "logout.out");
        // const _loginPath = getCurrentLoginPath(_domain);
        addLoginRedirect();
        Router.replace(LOGIN_PATHS.LOGIN);
      } else {
        return Promise.reject({
          code: res?.data?.code
        });
      }

      // 如果某个接口提示未登陆，则取消已经发送的请求
      removePendingRequest();
      //防止调用then错误弹窗提示
      return Promise.reject("logout.out");
    }
    // msg长度超过100时，返回"System error"
    if (res?.data?.msg?.length > 500) {
      message.error("System error");
      return Promise.reject({
        msg: "System error"
      });
    }

    // 缓存数据到本地
    if (isClient) {
      // _saveDataToLocalStorage(res);
    }

    // 上报错误处理
    _handleResNotify(res);

    return res;
  },
  // 请求失败
  (error: AxiosError) => {
    // 请求已发出，但是不在2xx的范围
    return _handleErrorRes(error);
  }
);

export default service;
