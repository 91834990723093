import { COUNT_DOWN_TIMER } from "../config/hosts/index";

/**
 * 倒计时展示时间，单位s
 */
type callBack = (num: number) => void;

class CountDownTimer {
  _onCountDown: boolean = false; // 是否在倒计时中
  _countdown: number = COUNT_DOWN_TIMER; // 默认60s倒计时, 页面展示的倒计时数字
  _startTimer: number = 0; // 开始倒计时的时间戳
  _endTimer: number = 0; // 结束倒计时的时间戳
  _diffTimer: number = -1; //
  _countDownCallBack: callBack | undefined; // 倒计时回调

  /**
   * startTimer: 倒计时开始时间戳，单位ms，
   * countDownNumber： 倒计时时间，单位s
   */
  constructor(startTimer: number, countDownNumber: number) {
    this._startTimer = startTimer;
    this._endTimer = startTimer + 1000 * countDownNumber;
    this._countdown = countDownNumber;
  }

  // 触发开始倒计时动作
  startCountDown = () => {
    this._onCountDown = true;
    this._startFrame();
  };

  setCallback = (clallback: callBack) => {
    this._countDownCallBack = clallback;
  };

  // 停止倒计时
  stopCountDown = () => {
    this._onCountDown = false;
  };

  /**
   * 倒计时
   * requestAnimationFrame 比起 setTimeout、setInterval的优势主要有两点：
   * 1、requestAnimationFrame 会把每一帧中的所有DOM操作集中起来，在一次重绘或回流中就完成，并且重绘或回流的时间间隔紧紧跟随浏览器的刷新频率，一般来说，这个频率为每秒60帧。
   * 在隐藏或不可见的元素中，requestAnimationFrame将不会进行重绘或回流，这当然就意味着更少的的cpu，gpu和内存使用量。
   */
  _startFrame = () => {
    requestAnimationFrame(this._handleCountDown);
  };

  _handleCountDown = () => {
    if (!this._onCountDown) {
      return;
    }
    const currentTime = new Date().getTime();
    // 1秒倒计时一次
    const diffTimer = parseInt((currentTime - this._startTimer) / 1000 + "");
    if (diffTimer != this._diffTimer) {
      this._diffTimer = diffTimer;
      this._countdown--;
      this._countDownCallBack && this._countDownCallBack(this._countdown);
    }
    if (currentTime <= this._endTimer && this._countdown > 0) {
      this._startFrame();
    } else {
      this.stopCountDown();
    }
  };
}
export { CountDownTimer };
