// TODO， 移到cms的颜色配置中去， @于衍
export const DASHBOARD_COLOR = {
  pieFill: "#191B23",
  pieInnerStroke: "#262938",
  pieOuterStroke: "#252733",
  registered: "#50A9A4",
  verified: "#457977",
  deposited: "#183F3D",
  transparent: "transparent",
  spot: "#F17E00",
  yield: "#AA5900",
  structured: "#6D4300",
  strategies: "#B79269",
  venture: "#844D12",
  cold: "#251606",
  cumulativePayout: "#D79B35",
  unsettledRevenue: "#9B6F24",
  desktop: "#50A9A4",
  mobile: "#F17E00",
  general: "#457977",
  trade: "#50A9A4",
  intelligence: "#F17E00",
  wallet: "#A55600",
  strategy: "#F17E00",
  history: "#663700",
  others: "#663700",
  aum: "#F17E00",
  subscriber: "#F17E00",
  session: "#F17E00",
  volume: "#F17E00",
  trader: "#F17E00",
  "spot aum": "#F17E00",
  buy: "#F17E00",
  sell: "#AA5900",
  "stable coin": "#F17E00",
  crypto: "#AA5900"
};

export const AUM_STATISTICS_TYPE = {
  DAILY: "DAILY",
  MONTHLY: "MONTHLY"
};

export const PLATFORM_STATISTICS_TYPE = {
  MONTHLY_TWELVE: "MONTHLY_TWELVE",
  MONTHLY_LATEST: "MONTHLY_LATEST"
};

export const PLATFORM_LABEL_TYPES = {
  visitors: "visitors",
  logins: "logins",
  sessions: "sessions"
};

export const PLATFORM_TYPES = ["desktop", "mobile"];
export const PLATFORM_LABELS = [
  PLATFORM_LABEL_TYPES.visitors,
  PLATFORM_LABEL_TYPES.logins,
  PLATFORM_LABEL_TYPES.sessions
];

export const PLATFORM_DATA_LABELS = {
  [PLATFORM_LABEL_TYPES.visitors]: "uniqueVisitors",
  [PLATFORM_LABEL_TYPES.logins]: "logins",
  [PLATFORM_LABEL_TYPES.sessions]: "sessions"
};

export const RECENT_OPERATION_TYPE = {
  // 客户注册
  CUSTOMER_REGISTER: "customer register",
  // 客户登录
  CUSTOMER_LOGIN: "customer login",
  // 客户登出
  CUSTOMER_LOGOUT: "customer logout",

  // COMPANY_ASSETS: "Company Assets",
  //策略申购意向
  STRATEGY_INTENTION_DEPOSIT: "strategy intention deposit",
  //策略赎回意向
  STRATEGY_INTENTION_REDEEM: "strategy intention redeem",
  // 策略扣款
  STRATEGY_DEDUCTION: "strategy deduction",
  // 策略更新持仓
  STRATEGY_UPDATE_POSITION: "strategy update position",
  // 策略批量更新持仓
  STRATEGY_BATCH_UPDATE_POSITION: "strategy batch update position",
  // 风投申购
  VENTURE_SUBSCRIBE: "venture subscribe",
  // 风投赎回
  VENTURE_REDEEM: "venture redeem",
  // 股权赎回
  SHARES_TRANSFER: "shares transfer",
  // 下单
  PLACE_ORDER: "place order",
  // 撤单
  CANCEL_ORDER: "cancel order",
  // 活期理财申购
  FLEXIBLE_SUBSCRIBE: "flexible subscribe",
  // 活期理财赎回
  FLEXIBLE_REDEEM: "flexible redeem",
  // 活期理财自动申购
  FLEXIBLE_AUTO_SUBSCRIBE: "flexible auto subscribe",
  // 活期理财自动赎回
  FLEXIBLE_AUTO_REDEEM: "flexible auto redeem",
  // 定投理财申购
  INVESTMENT_SUBSCRIBE: "investment subscribe",
  // 定投理财赎回
  INVESTMENT_REDEEM: "investment redeem",
  // 定期理财申购
  FIXED_DEPOSIT_SUBSCRIBE: "fixed deposit subscribe",
  // 定期理财撤销
  FIXED_DEPOSIT_REVOKE: "fixed deposit revoke",
  // 用户添加银行卡
  ADD_BANK_CARD: "add bank card",
  // 用户提交kyc信息
  ADD_KYC_INFO: "add kyc info",
  // 用户修改kyc信息
  UPDATE_KYC_INFO: "update kyc info",
  // 法币充币
  FIAT_DEPOSIT: "fiat deposit",
  // 法币提币
  FIAT_WITHDRAW: "fiat withdraw",
  // 电子货币充币
  CRYPTO_DEPOSIT: "crypto deposit",
  // 电子货币提币
  CRYPTO_WITHDRAW: "crypto withdraw",
  // Staking申购
  STAKING_SUBSCRIBE: "staking subscribe",
  // Staking赎回
  STAKING_REDEEM: "staking redeem",
  // 冷钱包简版充币
  COLE_WALLET_SIMPLE_DEPOSIT: "cold wallet simple deposit",
  // NFT购买
  NFT_BUY: "nft buy",
  // 双币下单
  DUAL_PLACE: "dual place",
  // 冷钱包充币
  COLD_WALLET_DEPOSIT: "cold wallet deposit",
  // 冷钱包提币
  COLD_WALLET_WITHDRAW: "cold wallet withdraw",
  // 累计期权下单
  ACCUMULATOR_PLACE: "accumulator subscription",
  SNOWBALL_PLACE: "snowball subscription"
  // DEFAULT: "default"
};

export const AGENT_PLATFORM_TYPES = {
  WEB: "Desktop",
  IOS: "IOS",
  ANDROID: "ANDROID"
};

// total aum 接口返回属性列表
export const AUM_PROPERTY = ["spot", "yield", "structured", "strategies", "venture", "cold"];

// cms页面展示type
export const AUM_LABEL_TYPES = ["Spot", "Yield", "Structured", "Strategies", "Venture", "Cold"];

// CMS 客户详情页，aum柱状图显示需要添加当月数据，当月数据从wallet/info接口获取，界面显示和接口返回字段对应关系
export const AUM_AMOUNT_DATA = {
  Spot: "spotAmount",
  Yield: "yieldAmount",
  Structured: "structuredAmount",
  Strategies: "strategyAmount",
  Venture: "ventureAmount",
  Cold: "coldWalletAmount"
};

// cms product table筛选选项
export const filterOptions = [
  { value: "", label: "All" },
  { value: "Y", label: "Y" },
  { value: "N", label: "N" }
];
