import { isClient, SAAS_COMMON_CACHE_PRIORITY_KEYS } from "@aspen/libs";
import { ISaasCommonCacheKeys } from "@aspen/model";
import {
  getSaasInterestVentureList,
  getSaasWalletInfo,
  SAAS_DATA_CACHES_KEYS
} from "@aspen/services";
import { updateSaasWalletInfo, useAppDispatch, updateSaasInterestVentures } from "@aspen/store";

/**
 * @param key 唯一key名称
 * @param params 请求参数
 */

function useSaasFecthers(key: ISaasCommonCacheKeys, params: any, cacheKey?: string) {
  if (!key) return;
  const dispatch = useAppDispatch();

  const updateBeforeRequest = (updateFun: Function) => {
    if (isClient) {
      const cacheData = (cacheKey && (localStorage.getItem(cacheKey.toString()) as string)) || null;
      const _cacheResDataKey = SAAS_DATA_CACHES_KEYS[key].cacheResDataKey;
      if (cacheData != undefined) {
        try {
          if (_cacheResDataKey) {
            JSON.parse(cacheData)?.[_cacheResDataKey] &&
              dispatch(updateFun(JSON.parse(cacheData)?.[_cacheResDataKey]));
          } else {
            dispatch(updateFun(JSON.parse(cacheData)));
          }
        } catch (error) {
          // ignore
        }
      }
    }
  };

  const fetcherSaasWalletInfo = () => {
    updateBeforeRequest(updateSaasWalletInfo);
    return getSaasWalletInfo().then((res) => {
      if (res?.code == "0") {
        dispatch(updateSaasWalletInfo(res?.data));
        return res?.data;
      }
    });
  };
  const fetcherSaasInterestVentures = () => {
    updateBeforeRequest(updateSaasInterestVentures);
    return getSaasInterestVentureList(params).then((res) => {
      if (res?.code == "0") {
        const data = (res?.data?.rows ?? []).map((item: { id: string }) => item.id);
        dispatch(updateSaasInterestVentures(data));
        return data;
      }
    });
  };

  let fetcher;
  switch (key) {
    case SAAS_COMMON_CACHE_PRIORITY_KEYS.saasWalletInfo:
      fetcher = fetcherSaasWalletInfo;
      break;
    case SAAS_COMMON_CACHE_PRIORITY_KEYS.saasInterestVentures:
      fetcher = fetcherSaasInterestVentures;
      break;
    default:
  }
  return fetcher;
}

export { useSaasFecthers };
