import { NextRouter } from "next/router";
import {
  IRole,
  SAAS,
  SAAS_IS_LOGIN,
  SAAS_LOGIN_REDIRECT,
  SAAS_REDIRECT_ID,
  SAAS_USERID
} from "../../config";
import { checkAuth, checkPathName } from "../authUtils";
import { isClient } from "../common";

export const addSaasLoginRedirect = () => {
  if (window.location.pathname.includes(SAAS.LOGIN)) return;
  const currentPath = window.location.pathname.replace("/zh", "") + window.location.search;
  currentPath && window.localStorage.setItem(SAAS_LOGIN_REDIRECT, currentPath);
  const saasUserId = window.localStorage.getItem(SAAS_USERID);
  saasUserId && window.localStorage.setItem(SAAS_REDIRECT_ID, saasUserId);
};
export const checkSaasRouterAuth = (routeConfig: IRole, router: NextRouter) => {
  const toLogoutPath = SAAS.LOGIN;
  let asPath = router.asPath;
  let pathname = checkPathName(router.pathname != "/_error" ? router.pathname : asPath);
  const toDefaultPath = SAAS.VENTURES;
  const isLogin = (isClient && localStorage.getItem(SAAS_IS_LOGIN)) || false;
  const targetRouterConfig = routeConfig?.[pathname];
  // 如果路由不存在跳转至VENTURES
  if (pathname === "/" || !pathname || !targetRouterConfig || pathname == "_error") {
    router.replace(toDefaultPath);
    return null;
  }
  if (isLogin) {
    // 判断当前角色是否有访问当前路由的权限
    if (checkAuth(routeConfig, pathname)) {
      return null;
    } else {
      router.replace(toLogoutPath);
      return null;
    }
  } else {
    if (targetRouterConfig?.needAuthenticated) {
      addSaasLoginRedirect();
      router.replace(toLogoutPath);
    } else {
      if (isClient) {
        router.replace(toLogoutPath);
      } else {
        router.replace(asPath);
      }
    }
    return null;
  }
};
