/**
 * 路由常量配置
 * 此配置只会增加，或者修改常量值，常量名尽量不要修改，
 * 不能修改的常量值需要明确标注
 */

/**
 * 各系统路由常量配置，在app中组合路由，生成对应routeConfig
 * saas、operation单独配置，portal、service、partner沿用之前路由
 * 各系统调用getPageHeader方法，checkAuth等方法时，从对应系统_app以传参形式传入
 */

// saas 路由
export const SAAS = {
  LOGIN: "/login",
  VENTURES: "/ventures",
  VENTURES_DETAIL: "/ventures/detail",
  STRATEGIES: "/strategies",
  STRATEGIES_DETAIL: "/strategies/detail",
  INVESTOR: "/investor",
  INVESTOR_DETAIL: "/investor/detail",
  INVESTOR_AUTHOR_LIST: "/investor/author",
  INVESTOR_AUTHOR_DETAIL: "/investor/author/detail",
  INVESTMENT: "/investment",
  WALLET: "/wallet"
};

// operation 路由
export const OPERATION_PATH = {
  CLIENT: "/client",
  CUSTOMER_DETAIL: "/customerDetail"
};

// partner 路由
export const PARTNER_PATH = {
  DASHBOARD: "/dashboard",
  CUSTOMER: "/customer",
  CUSTOMER_DETAIL: "/customerDetail",
  PRODUCT: "/product",
  OPERATION: "/operation",
  AUTH_APPLICATION: "/partnerAuthApplication",
  BILLING_HISTORY: "/billingHistory"
};

// service 路由
export const MANAGER_PATH = {
  CUSTOMER_MANAGEMENT: "/customerManagement"
};
// 不需要登陆的路由
// LOGIN_PATHS、BEFORE_LOGIN_SENAD_EMAIL_PATH、注释 等 整理/移除/等 @于衍
export const LOGIN_PATHS = {
  LOGIN: "/login",
  LOGIN_SAFETY_VERIFY: "/login/safetyVerify",
  LOGIN_REGISTER: "/login/register",
  LOGIN_RESET: "/login/reset",
  LOGIN_REGISTER_MULTI: "/login/registerMulti"
};

export const POLICY_PATH = "/policy";
export const AGREEMENT_PATH = "/agreement";
export const AUTHORIZATION_AGREEMENT_PATH = "/authorizationAgreement";
export const TOOLS = "/tools"; // aspen digital only
export const DOWNLOAD_PATH = "/download"; // 已生成二维码无法修改
export const DOWNLOAD_BILL_PATH = "/portfolioReport";

// 需要登陆的路由
export const HOME_PATH = "/home";
export const TRADE_PATH = "/trade";
export const TRADE_PATH_DEFAULT_PATH = `${TRADE_PATH}/BTC_USDT`;
export const YIELD_PATHS = {
  YIELD: "/yield",
  YIELD_MY_INVESTMENT_PLAN: "/yield/myDcaPlan",
  YIELD_ADD_INVESTMENT_PLAN: "/yield/addInvestmentPlan",
  YIELD_CREATE_DCA_PLAN: "/yield/createDca",
  YIELD_INVESTMENT_HISTORY: "/yield/dcaHistory",
  YIELD_SUBSCRIBE_FIXED_YIELD: "/yield/subsFixed",
  YIELD_APP_BTC_YTD: "/yield/appBtcYtd"
};

export const STRUCTURED_PATH = {
  STRUCTURED: "/structured",
  STRUCTURED_ACCUMULATOR_SUBSCRIBE: "/structured/accumuSubscribe",
  STRUCTURED_SNOWBALL_SUBSCRIBE: "/structured/snowSubscribe",
  STRUCTURED_DUAL_SUBSCRIBE: "/structured/dualSubscribe"
};

export const STRATEGIES_PATHS = {
  STRATEGIES: "/strategies",
  STRATEGIES_DETAIL: "/strategies/detail"
};

export const VENTURES_PATHS = {
  VENTURES: "/ventures",
  VENTURES_DETAIL: "/ventures/detail"
};

// Note:  如果web改了新闻相关路由名称，需要通知APP修改
export const INTELLIGENCE_PATHS = {
  INTELLIGENCE: "/intelligence",
  INTELLIGENCE_INSIGHTS: "/intelligence/insights",
  INTELLIGENCE_NEWS: "/intelligence/news",
  INTELLIGENCE_RESEARCH: "/intelligence/research",
  INTELLIGENCE_BRIEFING: "/intelligence/briefing",
  INTELLIGENCE_EXPERT: "/intelligence/expert", // 专栏首页
  INTELLIGENCE_EXPERT_LIST: "/intelligence/expert/list", // 专栏列表
  INTELLIGENCE_EXPERT_AUTHOR_DETAIL: "/intelligence/expert/authorDetail", // 专栏作家个人中心

  // 以下不需要登陆
  INTELLIGENCE_NEWS_DETAIL: "/intelligence/news/detail",
  INTELLIGENCE_RESEARCH_DETAIL: "/intelligence/research/detail",
  INTELLIGENCE_MARKET_DAILY_DETAIL: "/intelligence/briefing/detail",
  INTELLIGENCE_NOTICE_DETAIL: "/intelligence/noticeDetail",
  INTELLIGENCE_EXPERT_DETAIL: "/intelligence/expert/detail"
};

export const HISTORY_PATHS = {
  HISTORY: "/history",
  HISTORY_APPLICATION_DETAIL: "/history/applicationDetail"
};

export const PROFILE_PATHS = {
  PROFILE: "/profile",
  PROFILE_MY_INVITATION: "/profile/myInvitation",
  PROFILE_KYC_VERIFICATION: "/profile/kyc/verification",
  PROFILE_KYC_RESULT: "/profile/kyc/result",
  PROFILE_AGENT_AUTHORIZE: "/profile/agent/authorize",
  PROFILE_KYC_APPUPLOAD: "/profile/kyc/appUpload"
};

export const WALLET_PATHS = {
  WALLET: "/wallet",
  WALLET_DEPOSIT: "/wallet/deposit",
  WALLET_DEPOSIT_OTC: "/wallet/deposit/otc",
  WALLET_WITHDRAW: "/wallet/withdraw",
  WALLET_DEPOSIT_USDC: "/wallet/deposit/usdc",
  WALLET_WITHDRAW_USDC: "/wallet/withdraw/usdc",
  WALLET_ADDACCOUNT: "/wallet/addAccount",
  WALLET_HISTORY: "/wallet/history",
  WALLET_AUMFEE: "/wallet/AUMFee",
  WALLET_VAULT: "/wallet/vault",
  WALLET_VAULT_STORAGE: "/wallet/vault/storage",
  WALLET_VAULT_WITHDRAW: "/wallet/vault/withdraw",
  WALLET_VAULT_HISTORY: "/wallet/vault/history",
  WALLET_WITHDRAW_WHITELIST_HISTORY: "/wallet/withdraw/whitelistHistory",
  WALLET_WITHDRAW_WHITELIST_MANAGEMENT: "/wallet/withdraw/addressManage",
  WALLET_WITHDRAW_ADD_ADDRESS: "/wallet/withdraw/addAddress",
  WALLET_ACCOUNT_HISTORY: "/wallet/accountHistory"
};

export const ASSETS_PATHS = {
  ASSETS: "/assets",
  ASSETS_MOBILE: "/assets/mobile"
};

export const VIP_PATHS = {
  VIP: "/vip"
};
