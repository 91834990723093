export const GA_EVENT_TAG = {
  Click: "ck",
  Modal: "open"
};
//自定义事件
export const GA_EVENT_NAME = {
  client: {
    searchAgentInfo: "cl_searchAgentInfo",
    managerCustomer: "cl_managerCustomer",
    applyAuthorize: "cl_applyAuthorize",
    applyOperate: "cl_applyOperate",
    loadMore: "cl_loadMore"
  },
  //DCA相关
  dca: {
    createPlan: "createPlan",
    switchStrategy: "switchStrategy",
    previewExistPlan: "preExistPlan", // 与其他计划冲突的弹窗 露出埋点
    previewPlan: "previewPlan",
    handleClickOnCreatePlan: "createDCAPlan",
    loadMorProducts: "dca_loadMorProducts",
    loadMorMyPlan: "dca_loadMorMyPlan",
    submitPlan: "submitPlan",
    historyDetail: "historyDetail", // 定投历史记录 查看详情 弹窗
    viewDCADetail: "viewDCADetail", // 我的定投计划 查看计划详情弹窗
    openCancelModal: "cancelModal", // 我的定投计划 取消计划的二次弹窗
    confirmCancel: "confirmCancel", // 我的定投计划 取消计划的弹窗确认按钮事件
    autoFinancing: "openFlexible", // 开启转入活期理财 点击
    autoFinancingModal: "openFlexible", // 开启转入活期理财 确认弹窗
    autoFinancingCloseModal: "closeFlexible", // 关闭转入活期理财 确认弹窗
    autoFinancingCloseClick: "closeFlexible", // 关闭转入活期理财 点击
    autoStaking: "openAutoStake", // 开启转入Staking 点击
    autoStakingModal: "openAutoStake", // 开启转入Staking 确认弹窗
    autoStakingCloseModal: "closeAutoStake", // 关闭转入Staking 确认弹窗
    autoStakingCloseClick: "closeAutoStake", // 关闭转入Staking 点击
    changeMyDcaTab: "switchList"
  },
  download: {
    openDownloadPage: "openDownloadPage",
    downloadGoogleApp: "downloadGoogleApp",
    downloadAppleApp: "downloadAppleApp"
  },
  common: {
    navigation: "navigation",
    accessApp: "access_via_app",
    switchLanguage: "switchLanguage",
    pageRefresh: "pageRefresh", // 页面手动刷新的时候上报
    changeVerifyMethod: "change_verify_method"
  },
  trade: {
    switchTimeTab: "switchTimeTab",
    switchSymbolPair: "switchSymbolPair",
    kline: "kline",
    depth: "depth",
    klineOrigin: "klineOrigin",
    klineTradingView: "klineTradingView",
    switchBuyOrSell: "switchBuyOrSell",
    switchOrderType: "switchOrderType",
    placeOrder: "placeOrder",
    confirmCancelOrder: "confirmCancelOrder",
    viewOrderDetail: "viewOrderDetail",
    cancelOrderModal: "cancelOrderModal",
    refreshOpenOrder: "refreshOpenOrder",
    refreshHistoryOrder: "refreshHistoryOrder",
    switchOrderList: "switchOrderList",
    favorites: {
      oneCkAddition: "oneCkAddition", // 一键添加自选
      favAdd: "favAdd", // 打开自选添加modal
      favEdit: "favEdit", // 打开自选编辑modal
      favSave: "favSave", // 保存当前自选列表
      fav: "fav", // 单个点击自选列表取消or添加 不保存
      favSelectAll: "favSelectAll", // 添加所有自选列表当前币种 不保存
      favRemove: "favRemove", // 移除所有自选列表当前币种 不保存
      favSingleChoice: "favSingleChoice", // 点击单个修改交易对自选
      tradingPair: "tradingPair" // 点击交易对打开modal
    }
  },
  yield: {
    switchYieldList: "switchYieldList", // 切换理财类型
    subscribeModal: "subscribeModal", // 打开申购弹窗，活期，定期，质押事件名称一致，参数传不同type
    redeemModal: "redeemModal", // 打开赎回弹窗
    submitSubscribe: "submitSubscribe", // 申购弹窗确认
    submitRedeem: "submitRedeem", // 赎回弹窗确认
    switchAutoCompoundModal: "switchAutoCompoundModal", // 打开切换自动续费弹窗
    submitAutoCompound: "submitAutoCompound", // 自动申购确认
    stepModal: "previewPlan", // 二次验证弹窗点击下一步,
    noDepositModal: "noDepositModal",
    submitAutoPurchase: "submitAutoPurchase" // 利息买币弹窗 确认按钮
  },
  // 提币白名单相关
  whithdraw: {
    addAddress: "add_Withdraw_Address",
    selectAddress: "selectAddress", // 提笔选择地址 按钮
    deleteAddress: "delete_Withdraw_Address",
    applyWhite: "apply_Withdraw_Address_White",
    removeWhite: "remove_Withdraw_Address_White",
    openWhite: "open", // 开启提币白名单开关
    offWhite: "off", // 关闭提币白名单开关
    confirmOpenWhite: "confirmOpen", // 确认开启 提币白名单开关
    confirmOffWhite: "confirmOff", // 确认关闭 提币白名单开关
    previewHistory: "view_Withdraw_Address_History",
    previewAdd: "view_Withdraw_Address_Add",
    crypto: "apply_Withdraw_Crypto",
    circleFiat: "withdraw_Circle_Fiat",
    whiteList: "whitelist", // 点击申请白名单，弹出安全验证弹窗
    remove: "remove", // 删除白名单
    confirmRemove: "confirmRemove", // 移除白名单弹窗 确认按钮
    delete: "delete", // 删除地址按钮 事件
    confirmDelete: "confirmDelete" // 删除地址弹窗 确认按钮
  },
  intelligence: {
    switchTab: "switchTab",
    insights: {
      switchTab: "switchTab",
      banner: "banner",
      list_detail: "list",
      loadMore: "loadmore"
    },
    news: {
      banner_detail: "banner", // 新闻板块 轮播图点击
      list_detail: "news_list_detail",
      list_loadMore: "loadmore" // 新闻板块 加载更多
    },
    research: {
      list_detail: "list",
      list_loadMore: "loadmore",
      detailtDownloadFile: "res_detail_downloadFile"
    },
    briefing: {
      loadMore: "bf_loadMore",
      switchTab: "bf_switchTab"
    },
    expert: {
      articleList: "articleList", // 点击专栏列表的某一篇文章
      listExpert: "listExpert", // 专栏文章列表点击作家
      featured_all: "ex_featured_all",
      detail: "ex_detail",
      loadMore: "ex_loadMore",
      viewAll: "viewAllExpert",
      authorDetail: "featuredExpert", // 专栏作家主页
      follow: "follow",
      unfollow: "unfollow",
      readMore: "ex_readMore",
      close: "ex_close",
      contactUsModal: "ex_contactUsModal", // 专栏详情页 底部的 联系我们弹窗的打开按钮
      confirmFeedBack: "ex_confirmFeedback" // 联系我们弹窗的确认按钮
    },
    expert_list: {
      switchTab: "ex_list_switchTab",
      follow: "ex_list_follow",
      unfollow: "ex_list_unfollow",
      goAuthorDetail: "ex_list_goAuthorDetail",
      goAuthorArticleDetail: "ex_list_goAuthorArticleDetail"
    },
    /*
    TIPS: recommend 里面的key, 有动态使用的地方，例如:widgets/intelligence/NewsCard.tsx line 44
      let eventType = "news"
      moduleName: GA_EVENT_NAME.intelligence.recommend[`${eventType ?? "common"}_more`]
    所以这里面的key，修改前需要注意下
     */
    recommend: {
      common_more: "rec_common_more",
      reloadAnnouncement: "reloadAnnouncement", // 右侧公告的刷新按钮
      announcement_detail: "rec_announcement_detail",
      news_more: "moreNews",
      news_detail: "rec_news_detail",
      research_more: "moreResearch",
      research_detail: "rec_research_detail",
      expert_more: "moreExpert",
      expert_detail: "rec_expert_detail", // 暂时没用
      expert_author_detail: "rec_expert_author_detail", // 暂时没用
      daily_more: "moreDaily",
      daily_detail: "rec_daily_detail",
      weekly_more: "moreWeekly",
      weekly_detail: "rec_weekly_detail",
      monthly_more: "moreMonthly",
      monthly_detail: "rec_monthly_detail",
      follow_more: "moreFollowing",
      follow_detail: "rec_follow_detail",
      followingExpert: "followingExpert", // 推荐 我关注的 点击专栏作家
      followingArticle: "followingArticle", // 推荐 我关注的 点击文章事件
      mobile_downloadFile: "rec_downloadFile" // 资讯 -> 研报 -> 详情页 下载附件 仅限手机端
    }
  },
  accumulator: {
    customizeAccumu: "customizeAccumu", // 点击 咨询定制服务 按钮时的事件
    viewAllProducts: "Accu_viewAllProducts",
    customization: "submitCustomization", // 咨询定制服务弹窗 确认按钮点击的事件
    subscribe: "subscribe",
    cancelSubscribePage: "Accu_cancelSubscribe",
    submitSubscribe: "submitSubscribe",
    openSuspended: "suspended", //累计期权 申购按钮点击时 不在申购时间段弹窗
    subsResult: "subsResult", // 累计期权 申购按钮点击时 根据订单状态显示弹窗
    walletModal: "Accu_walletModal"
  },
  snowball: {
    customizeSnow: "customizesnow", // 点击 咨询定制服务 按钮时的事件
    customization: "submitCustomization", // 咨询定制服务弹窗 确认按钮点击的事件
    viewAllSnow: "viewAllSnow",
    snowSubscribe: "snowsubscribe",
    cancelSubscribePage: "Snow_cancelSubscribe",
    submitSubscribe: "submitSubscribe",
    openSuspended: "suspended", // 雪球 申购按钮点击时 不在申购时间段弹窗
    subsResult: "subsResult" // 雪球 申购按钮点击时 根据订单状态显示弹窗
  },
  dualSubscribe: {
    submitSubscribe: "submitSubscribe", // 双币 或 累计期权 申购弹窗申购按钮点击
    subsResult: "subsResult" // 双币 或 累计期权 申购按钮点击时 根据订单状态显示弹窗
  },
  myInvestment: {
    tradeDetail: "tradeDetail",
    strategyView: "strategyView",
    strategyCancel: "strategyCancel"
  },
  login: {
    loginPartner: "lg_loginPartner",
    sendEmailCode: "lg_sendEmailCode",
    loginBtnClick: "login", // 点击登录按钮时
    loginService: "lg_loginService",
    inviteCodeRegister: "lg_inviteCodeRegister",
    switchVerifyMethod: "switchVerifyMethod"
  },
  profile: {
    agent: {
      submitAuth: "pf_submitAuth",
      approveAuth: "pf_approveAuth",
      rejectAuth: "pf_rejectAuth",
      rejectAuthModal: "pf_rejectAuthModal"
    },
    kyc: {
      appViewKYC2: "kyc_AppViewKYC2"
    },
    security: {
      securityRisk: "securityRisk",
      skipRiskReminder: "skipRiskReminder",
      bindGA: "bindGA",
      bindGANextStep: "bindGANextStep",
      confirmBindGA: "confirmBindGA",
      unbindGA: "unbindGA",
      confirmUnBindGA: "confirmUnBindGA",
      bindPhone: "bindPhone",
      confirmBindPhone: "confirmBindPhone",
      changePhone: "changePhone",
      confirmChangePhone: "confirmChangePhone",
      unbindPhone: "unbindPhone",
      confirmUnbindPhone: "confirmUnbindPhone",
      changePwd: "changePwd",
      confirmChangePwd: "confirmChangePwd"
    },
    changePWModal: "changePWModal", // 点击修改密码 弹窗修改密码弹窗
    changePWSuccess: "changePWSuccess", // 修改密码按钮点击成功时的事件
    bindGoogleModal: "bindGoogleModal", // 谷歌验证弹窗
    viewGoogleKey: "viewGoogleKey", // 点击查看密钥
    confirmBindGoogle: "confirmBindGoogle", // 确认开启按钮点击
    unbindGoogleModal: "unbindGoogleModal", // 解绑谷歌验证的弹窗
    confirmUnBindGoogle: "confirmUnBindGoogle", // 确认解绑按钮点击
    invitationCode: "invitationCode", // 点击复制验证码
    invitationLink: "invitationLink", // 点击复制邀请链接
    switchServiceModal: "switchServiceModal", // 关闭/开启帮客操作的弹窗
    confirmSwitchService: "confirmSwitchService" // 帮客操作确认弹窗：点击确认开启/关闭
  },
  history: {
    switchTab: "switchTab", // 切换列表的事件
    switchYieldTab: "switchYieldTab", // 历史理财 切换 活期、定期、质押的事件
    switchStructuredTab: "switchStructuredTab", // 历史结构化列表 切换 dual\accumulator\snowball 事件
    snowDetail: "snowDetail" // 历史结构化列表 snowball列表，展开某一条订单详情
  },
  strategies: {
    redeemModal: "redeemModal", // 钱包 策略 赎回弹窗
    detail: {
      subsModal: "subsModal",
      redeemModal: "redeemModal",
      submitSubscribe: "submitSubscribe",
      redeemConfirm: "submitRedeem",
      subsResult: "subsResult", // 申购按钮点击后 申购结果弹窗 事件
      redeemResult: "redeemResult", // 赎回按钮点击后 赎回结果弹窗 事件
      openLink: "openLink"
    }
  },
  structured: {
    switchStructureList: "switchStructureList", // 切换 双币\累计期权\雪球 tab点击
    switchProduct: "switchProduct", // 切换产品类型
    switchDirection: "switchDirection", // 切换产品方向
    switchStructuredTab: "switchStructuredTab", // 钱包 切换 双币或累计期权
    dualDetail: "dualDetail", // 钱包 查看双币详情
    accumulatorDetail: "accumulatorDetail", // 钱包 累计期权详情
    snowDetail: "snowDetail" // 钱包  雪球详情
  },
  ventures: {
    switchVentureList: "switchVentureList", // 切换一二级 分头产品列表
    listSecondary: "listSecondary", // 点击风投出售按钮 弹出风投出售弹窗
    submitListSecondary: "submitListSecondary", // 风投出售弹窗 提交按钮事件
    detail: {
      subsModal: "subsModal",
      submitSubscribe: "submitSubscribe", // 点击申购弹窗
      subsResult: "subsResult", // 申购提交后的弹窗事件
      redeemModal: "redeemModal",
      submitRedeem: "submitRedeem", // 赎回提交按钮事件
      redeemResult: "redeemResult", // 赎回提交后的结果弹窗事件
      openLink: "openLink"
    }
  },
  wallet: {
    addCircleAccount: "addCircleAccount",
    loadMoreAum: "loadMoreAum",
    hideAssets: "hideAssets", // 资产不可见
    showAssets: "showAssets", // 资产可见
    storageVault: "storageVault", // 冷钱包 存入按钮
    switchTab: "switchTab", // 切换账户持仓列表 spot/yield/structured/strategy/venture
    spot: {
      depositUSDCModal: "depositUSDCModal", // 现货 UDSC item 点击 deposit 按钮事件
      withdrawUSDCMethod: "withdrawUSDCMethod" // 现货 UDSC item 点击 withdraw 按钮事件
    },
    yield: {
      switchYieldTab: "switchYieldTab", //理财账户 切换定期、活期列表 按钮
      subscribeModal: "subscribeModal", // 理财 活期 申购弹窗
      redeemModal: "redeemModal", // 理财 活期 赎回弹窗
      fixedDetail: "fixedDetail", // 理财 定期 view 按钮
      cancelFixedModal: "cancelFixedModal", // 理财 定期 cancel 按钮
      fixedLoadMore: "loadmore", // 理财 定期 loadmore 按钮
      submitCancelFixed: "submitCancelFixed", //理财 定期 取消弹窗的 确认按钮
      stakingDetail: "stakingDetail", // 理财 质押 查看详情弹窗
      stakeModal: "stakeModal", // 理财 质押 按钮弹窗
      unstakeModal: "unstakeModal" // 理财 质押 解仓按钮弹窗
    },
    venture: {
      subscribeVenture: "subscribeVenture" // 风投账户 申购
    },
    otcCkConfirm: "confirm" // otc confirm按钮
  },
  home: {
    symbolPair: "symbolPair", // 首页 点击交易对触发跳转的点击
    showTotalAssets: "showTotalAssets", // 首页 资产分布图 小眼睛睁开
    hideTotalAssets: "hideTotalAssets", // 首页 资产分布图 小眼睛闭上
    totalAssetsLine: "changeAssetsMonth",
    depositaCrypto: "depositaCrypto", // 首页 数字入金
    purchaseUSTD: "purchaseUSTD", // 首页 购买USTD
    trade: "trade", // 首页 购买USTD
    dailyBriefing: "dailyBriefing", // 首页 每日简报
    viewAllVenture: "viewAllVenture", // 查看所有风投产品
    viewAllStrategy: "viewAllStrategy", // 查看所有策略产品
    viewAllYield: "viewAllYield" // 查看所有理财产品
  },
  kyc: {
    submitIndSur: "submitIndSur",
    submitInsSur: "submitInsSur",
    submitInsDoc: "submitInsDoc",
    submitIndDoc: "submitIndDoc",
    submitRiskInd: "submitRiskInd",
    submitRiskIns: "submitRiskIns",
    confirmIndividualVerification: "confirmIndividualVerification",
    confirmInstitutionVerification: "confirmInstitutionVerification"
  },
  cms: {
    recentDetail: "cms_recentDetail"
  },
  assets: {
    changePnlDate: "changePnlDate", // 点击 PNL 图标选择 7天或30天展示事件
    changeCustomDate: "changeCustomDate" // 自定义日期选择事件
  },
  register: {
    skipPhone: "skipPhone"
  },
  portfolioReport: {
    preview: "preview",
    download: "download",
    loadMore: "more"
  }
};

export const GA_ENTRANGE_TYPE = {
  homeTop: "homeTop",
  homeBottom: "homeBottom"
};
