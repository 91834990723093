export interface ICountriesData {
  chineseName?: string;
  commonName: string;
  displayName?: string;
  enable?: boolean;
  formalName?: string;
  id: string;
  countryCode: string;
  localName?: string;
  nationalFlag: string;
  startChar?: string;
  telephoneCode: string;
  flagIsImage?: boolean;
}

const tw = require("./images/tw.png").default;

const userCountries = {
  A: [
    {
      chineseName: "",
      commonName: "Afghanistan",
      countryCode: "AFG",
      displayName: "阿富汗",
      enable: true,
      formalName: "",
      id: "AF",
      localName: "",
      nationalFlag: "🇦🇫",
      startChar: "A",
      telephoneCode: "93"
    },
    {
      chineseName: "",
      commonName: "Albania",
      countryCode: "ALB",
      displayName: "阿尔巴尼亚",
      enable: true,
      formalName: "",
      id: "AL",
      localName: "",
      nationalFlag: "🇦🇱",
      startChar: "A",
      telephoneCode: "355"
    },
    {
      chineseName: "",
      commonName: "Algeria",
      countryCode: "DZA",
      displayName: "阿尔及利亚",
      enable: true,
      formalName: "",
      id: "DZ",
      localName: "",
      nationalFlag: "🇩🇿",
      startChar: "A",
      telephoneCode: "213"
    },
    {
      chineseName: "",
      commonName: "Andorra",
      countryCode: "AND",
      displayName: "安道尔",
      enable: true,
      formalName: "",
      id: "AD",
      localName: "",
      nationalFlag: "🇦🇩",
      startChar: "A",
      telephoneCode: "376"
    },
    {
      chineseName: "",
      commonName: "Angola",
      countryCode: "AGO",
      displayName: "安哥拉",
      enable: true,
      formalName: "",
      id: "AO",
      localName: "",
      nationalFlag: "🇦🇴",
      startChar: "A",
      telephoneCode: "244"
    },
    {
      chineseName: "",
      commonName: "Anguilla",
      countryCode: "AIA",
      displayName: "安圭拉",
      enable: true,
      formalName: "",
      id: "AI",
      localName: "",
      nationalFlag: "🇦🇮",
      startChar: "A",
      telephoneCode: "1264"
    },
    {
      chineseName: "",
      commonName: "Antigua and Barbuda",
      countryCode: "ATG",
      displayName: "安提瓜和巴布达",
      enable: true,
      formalName: "",
      id: "AG",
      localName: "",
      nationalFlag: "🇦🇬",
      startChar: "A",
      telephoneCode: "1268"
    },
    {
      chineseName: "",
      commonName: "Argentina",
      countryCode: "ARG",
      displayName: "阿根廷",
      enable: true,
      formalName: "",
      id: "AR",
      localName: "",
      nationalFlag: "🇦🇷",
      startChar: "A",
      telephoneCode: "54"
    },
    {
      chineseName: "",
      commonName: "Armenia",
      countryCode: "ARM",
      displayName: "亚美尼亚",
      enable: true,
      formalName: "",
      id: "AM",
      localName: "",
      nationalFlag: "🇦🇲",
      startChar: "A",
      telephoneCode: "374"
    },
    {
      chineseName: "",
      commonName: "Australia",
      countryCode: "AUS",
      displayName: "澳大利亚",
      enable: true,
      formalName: "",
      id: "AU",
      localName: "",
      nationalFlag: "🇦🇺",
      startChar: "A",
      telephoneCode: "61"
    },
    {
      chineseName: "",
      commonName: "Austria",
      countryCode: "AUT",
      displayName: "奥地利",
      enable: true,
      formalName: "",
      id: "AT",
      localName: "",
      nationalFlag: "🇦🇹",
      startChar: "A",
      telephoneCode: "43"
    },
    {
      chineseName: "",
      commonName: "Azerbaijan",
      countryCode: "AZE",
      displayName: "阿塞拜疆",
      enable: true,
      formalName: "",
      id: "AZ",
      localName: "",
      nationalFlag: "🇦🇿",
      startChar: "A",
      telephoneCode: "994"
    }
  ],
  B: [
    {
      chineseName: "",
      commonName: "Bahamas",
      countryCode: "BHS",
      displayName: "巴哈马",
      enable: true,
      formalName: "",
      id: "BS",
      localName: "",
      nationalFlag: "🇧🇸",
      startChar: "B",
      telephoneCode: "1242"
    },
    {
      chineseName: "",
      commonName: "Bahrain",
      countryCode: "BHR",
      displayName: "巴林",
      enable: true,
      formalName: "",
      id: "BH",
      localName: "",
      nationalFlag: "🇧🇭",
      startChar: "B",
      telephoneCode: "973"
    },
    {
      chineseName: "",
      commonName: "Bangladesh",
      countryCode: "BGD",
      displayName: "孟加拉国",
      enable: true,
      formalName: "",
      id: "BD",
      localName: "",
      nationalFlag: "🇧🇩",
      startChar: "B",
      telephoneCode: "880"
    },
    {
      chineseName: "",
      commonName: "Barbados",
      countryCode: "BRB",
      displayName: "巴巴多斯",
      enable: true,
      formalName: "",
      id: "BB",
      localName: "",
      nationalFlag: "🇧🇧",
      startChar: "B",
      telephoneCode: "1246"
    },
    {
      chineseName: "",
      commonName: "Belarus",
      countryCode: "BLR",
      displayName: "白俄罗斯",
      enable: true,
      formalName: "",
      id: "BY",
      localName: "",
      nationalFlag: "🇧🇾",
      startChar: "B",
      telephoneCode: "375"
    },
    {
      chineseName: "",
      commonName: "Belgium",
      countryCode: "BEL",
      displayName: "比利时",
      enable: true,
      formalName: "",
      id: "BE",
      localName: "",
      nationalFlag: "🇧🇪",
      startChar: "B",
      telephoneCode: "32"
    },
    {
      chineseName: "",
      commonName: "Belize",
      countryCode: "BLZ",
      displayName: "伯利兹",
      enable: true,
      formalName: "",
      id: "BZ",
      localName: "",
      nationalFlag: "🇧🇿",
      startChar: "B",
      telephoneCode: "501"
    },
    {
      chineseName: "",
      commonName: "Benin",
      countryCode: "BEN",
      displayName: "贝宁",
      enable: true,
      formalName: "",
      id: "BJ",
      localName: "",
      nationalFlag: "🇧🇯",
      startChar: "B",
      telephoneCode: "229"
    },
    {
      chineseName: "",
      commonName: "Bermuda",
      countryCode: "BMU",
      displayName: "百慕大",
      enable: true,
      formalName: "",
      id: "BM",
      localName: "",
      nationalFlag: "🇧🇲",
      startChar: "B",
      telephoneCode: "1441"
    },
    {
      chineseName: "",
      commonName: "Bhutan",
      countryCode: "BTN",
      displayName: "不丹",
      enable: true,
      formalName: "",
      id: "BT",
      localName: "",
      nationalFlag: "🇧🇹",
      startChar: "B",
      telephoneCode: "975"
    },
    {
      chineseName: "",
      commonName: "Bolivia",
      countryCode: "BOL",
      displayName: "玻利维亚（多民族国）",
      enable: true,
      formalName: "",
      id: "BO",
      localName: "",
      nationalFlag: "🇧🇴",
      startChar: "B",
      telephoneCode: "591"
    },
    {
      chineseName: "",
      commonName: "Bosnia and Herzegovina",
      countryCode: "BIH",
      displayName: "波斯尼亚和黑塞哥维那",
      enable: true,
      formalName: "",
      id: "BA",
      localName: "",
      nationalFlag: "🇧🇦",
      startChar: "B",
      telephoneCode: "387"
    },
    {
      chineseName: "",
      commonName: "Botswana",
      countryCode: "BWA",
      displayName: "博茨瓦纳",
      enable: true,
      formalName: "",
      id: "BW",
      localName: "",
      nationalFlag: "🇧🇼",
      startChar: "B",
      telephoneCode: "267"
    },
    {
      chineseName: "",
      commonName: "Brazil",
      countryCode: "BRA",
      displayName: "巴西",
      enable: true,
      formalName: "",
      id: "BR",
      localName: "",
      nationalFlag: "🇧🇷",
      startChar: "B",
      telephoneCode: "55"
    },
    {
      chineseName: "",
      commonName: "British Virgin Islands",
      countryCode: "VGB",
      displayName: "维尔京群岛（英国）",
      enable: true,
      formalName: "",
      id: "VG",
      localName: "",
      nationalFlag: "🇻🇬",
      startChar: "B",
      telephoneCode: "1284"
    },
    {
      chineseName: "",
      commonName: "Brunei",
      countryCode: "BRN",
      displayName: "文莱达鲁萨兰国",
      enable: true,
      formalName: "",
      id: "BN",
      localName: "",
      nationalFlag: "🇧🇳",
      startChar: "B",
      telephoneCode: "673"
    },
    {
      chineseName: "",
      commonName: "Bulgaria",
      countryCode: "BGR",
      displayName: "保加利亚",
      enable: true,
      formalName: "",
      id: "BG",
      localName: "",
      nationalFlag: "🇧🇬",
      startChar: "B",
      telephoneCode: "359"
    },
    {
      chineseName: "",
      commonName: "Burkina Faso",
      countryCode: "BFA",
      displayName: "布基纳法索",
      enable: true,
      formalName: "",
      id: "BF",
      localName: "",
      nationalFlag: "🇧🇫",
      startChar: "B",
      telephoneCode: "226"
    },
    {
      chineseName: "",
      commonName: "Burundi",
      countryCode: "BDI",
      displayName: "布隆迪",
      enable: true,
      formalName: "",
      id: "BI",
      localName: "",
      nationalFlag: "🇧🇮",
      startChar: "B",
      telephoneCode: "257"
    }
  ],
  C: [
    {
      chineseName: "",
      commonName: "Cambodia",
      countryCode: "KHM",
      displayName: "柬埔寨",
      enable: true,
      formalName: "",
      id: "KH",
      localName: "",
      nationalFlag: "🇰🇭",
      startChar: "C",
      telephoneCode: "855"
    },
    {
      chineseName: "",
      commonName: "Cameroon",
      countryCode: "CMR",
      displayName: "喀麦隆",
      enable: true,
      formalName: "",
      id: "CM",
      localName: "",
      nationalFlag: "🇨🇲",
      startChar: "C",
      telephoneCode: "237"
    },
    {
      chineseName: "",
      commonName: "Canada",
      countryCode: "CAN",
      displayName: "加拿大",
      enable: true,
      formalName: "",
      id: "CA",
      localName: "",
      nationalFlag: "🇨🇦",
      startChar: "C",
      telephoneCode: "1"
    },
    {
      chineseName: "",
      commonName: "Cape Verde",
      countryCode: "CPV",
      displayName: "佛得角",
      enable: true,
      formalName: "",
      id: "CV",
      localName: "",
      nationalFlag: "🇨🇻",
      startChar: "C",
      telephoneCode: "238"
    },
    {
      chineseName: "",
      commonName: "Cayman Islands",
      countryCode: "CYM",
      displayName: "开曼群岛",
      enable: true,
      formalName: "",
      id: "KY",
      localName: "",
      nationalFlag: "🇰🇾",
      startChar: "C",
      telephoneCode: "1345"
    },
    {
      chineseName: "",
      commonName: "Central African Republic",
      countryCode: "CAF",
      displayName: "中非共和国",
      enable: true,
      formalName: "",
      id: "CF",
      localName: "",
      nationalFlag: "🇨🇫",
      startChar: "C",
      telephoneCode: "236"
    },
    {
      chineseName: "",
      commonName: "Chad",
      countryCode: "TCD",
      displayName: "乍得",
      enable: true,
      formalName: "",
      id: "TD",
      localName: "",
      nationalFlag: "🇹🇩",
      startChar: "C",
      telephoneCode: "235"
    },
    {
      chineseName: "",
      commonName: "Chile",
      countryCode: "CHL",
      displayName: "智利",
      enable: true,
      formalName: "",
      id: "CL",
      localName: "",
      nationalFlag: "🇨🇱",
      startChar: "C",
      telephoneCode: "56"
    },
    {
      chineseName: "",
      commonName: "China",
      countryCode: "CHN",
      displayName: "中国",
      enable: true,
      formalName: "",
      id: "CN",
      localName: "",
      nationalFlag: "🇨🇳",
      startChar: "C",
      telephoneCode: "86"
    },
    {
      chineseName: "",
      commonName: "Colombia",
      countryCode: "COL",
      displayName: "哥伦比亚",
      enable: true,
      formalName: "",
      id: "CO",
      localName: "",
      nationalFlag: "🇨🇴",
      startChar: "C",
      telephoneCode: "57"
    },
    {
      chineseName: "",
      commonName: "Comoros",
      countryCode: "COM",
      displayName: "科摩罗",
      enable: true,
      formalName: "",
      id: "KM",
      localName: "",
      nationalFlag: "🇰🇲",
      startChar: "C",
      telephoneCode: "269"
    },
    {
      chineseName: "",
      commonName: "Congo, Republic",
      countryCode: "COG",
      displayName: "刚果",
      enable: true,
      formalName: "",
      id: "CG",
      localName: "",
      nationalFlag: "🇨🇬",
      startChar: "C",
      telephoneCode: "242"
    },
    {
      chineseName: "",
      commonName: "Congo,Democratic Republic",
      countryCode: "COD",
      displayName: "刚果（民主共和国）",
      enable: true,
      formalName: "",
      id: "CD",
      localName: "",
      nationalFlag: "🇨🇩",
      startChar: "C",
      telephoneCode: "243"
    },
    {
      chineseName: "",
      commonName: "Costa Rica",
      countryCode: "CRI",
      displayName: "哥斯达黎加",
      enable: true,
      formalName: "",
      id: "CR",
      localName: "",
      nationalFlag: "🇨🇷",
      startChar: "C",
      telephoneCode: "506"
    },
    {
      chineseName: "",
      commonName: "Cote d´Ivoire (Ivory Coast)",
      countryCode: "CIV",
      displayName: "科特迪瓦",
      enable: true,
      formalName: "",
      id: "CI",
      localName: "",
      nationalFlag: "🇨🇮",
      startChar: "C",
      telephoneCode: "225"
    },
    {
      chineseName: "",
      commonName: "Croatia",
      countryCode: "HRV",
      displayName: "克罗地亚",
      enable: true,
      formalName: "",
      id: "HR",
      localName: "",
      nationalFlag: "🇭🇷",
      startChar: "C",
      telephoneCode: "385"
    },
    {
      chineseName: "",
      commonName: "Cuba",
      countryCode: "CUB",
      displayName: "古巴",
      enable: true,
      formalName: "",
      id: "CU",
      localName: "",
      nationalFlag: "🇨🇺",
      startChar: "C",
      telephoneCode: "53"
    },
    {
      chineseName: "",
      commonName: "Curacao",
      countryCode: "CUW",
      displayName: "库拉索",
      enable: true,
      formalName: "",
      id: "CW",
      localName: "",
      nationalFlag: "🇨🇼",
      startChar: "C",
      telephoneCode: "599"
    },
    {
      chineseName: "",
      commonName: "Cyprus",
      countryCode: "CYP",
      displayName: "塞浦路斯",
      enable: true,
      formalName: "",
      id: "CY",
      localName: "",
      nationalFlag: "🇨🇾",
      startChar: "C",
      telephoneCode: "357"
    },
    {
      chineseName: "",
      commonName: "Czech Republic",
      countryCode: "CZE",
      displayName: "捷克",
      enable: true,
      formalName: "",
      id: "CZ",
      localName: "",
      nationalFlag: "🇨🇿",
      startChar: "C",
      telephoneCode: "420"
    }
  ],
  D: [
    {
      chineseName: "",
      commonName: "Denmark",
      countryCode: "DNK",
      displayName: "丹麦",
      enable: true,
      formalName: "",
      id: "DK",
      localName: "",
      nationalFlag: "🇩🇰",
      startChar: "D",
      telephoneCode: "45"
    },
    {
      chineseName: "",
      commonName: "Djibouti",
      countryCode: "DJI",
      displayName: "吉布提",
      enable: true,
      formalName: "",
      id: "DJ",
      localName: "",
      nationalFlag: "🇩🇯",
      startChar: "D",
      telephoneCode: "253"
    },
    {
      chineseName: "",
      commonName: "Dominica",
      countryCode: "DMA",
      displayName: "多米尼克",
      enable: true,
      formalName: "",
      id: "DM",
      localName: "",
      nationalFlag: "🇩🇲",
      startChar: "D",
      telephoneCode: "1767"
    },
    {
      chineseName: "",
      commonName: "Dominican Republic",
      countryCode: "DOM",
      displayName: "多米尼加共和国",
      enable: true,
      formalName: "",
      id: "DO",
      localName: "",
      nationalFlag: "🇩🇴",
      startChar: "D",
      telephoneCode: "1829"
    },
    {
      chineseName: "",
      commonName: "Dominican Republic",
      countryCode: "DOM",
      displayName: "多米尼加共和国",
      enable: true,
      formalName: "",
      id: "DO",
      localName: "",
      nationalFlag: "🇩🇴",
      startChar: "D",
      telephoneCode: "1809"
    },
    {
      chineseName: "",
      commonName: "Dominican Republic",
      countryCode: "DOM",
      displayName: "多米尼加共和国",
      enable: true,
      formalName: "",
      id: "DO",
      localName: "",
      nationalFlag: "🇩🇴",
      startChar: "D",
      telephoneCode: "1849"
    }
  ],
  E: [
    {
      chineseName: "",
      commonName: "Ecuador",
      countryCode: "ECU",
      displayName: "厄瓜多尔",
      enable: true,
      formalName: "",
      id: "EC",
      localName: "",
      nationalFlag: "🇪🇨",
      startChar: "E",
      telephoneCode: "593"
    },
    {
      chineseName: "",
      commonName: "Egypt",
      countryCode: "EGY",
      displayName: "埃及",
      enable: true,
      formalName: "",
      id: "EG",
      localName: "",
      nationalFlag: "🇪🇬",
      startChar: "E",
      telephoneCode: "20"
    },
    {
      chineseName: "",
      commonName: "El Salvador",
      countryCode: "SLV",
      displayName: "萨尔瓦多",
      enable: true,
      formalName: "",
      id: "SV",
      localName: "",
      nationalFlag: "🇸🇻",
      startChar: "E",
      telephoneCode: "503"
    },
    {
      chineseName: "",
      commonName: "Equatorial Guinea",
      countryCode: "GNQ",
      displayName: "赤道几内亚",
      enable: true,
      formalName: "",
      id: "GQ",
      localName: "",
      nationalFlag: "🇬🇶",
      startChar: "E",
      telephoneCode: "240"
    },
    {
      chineseName: "",
      commonName: "Eritrea",
      countryCode: "ERI",
      displayName: "厄立特里亚",
      enable: true,
      formalName: "",
      id: "ER",
      localName: "",
      nationalFlag: "🇪🇷",
      startChar: "E",
      telephoneCode: "291"
    },
    {
      chineseName: "",
      commonName: "Estonia",
      countryCode: "EST",
      displayName: "爱沙尼亚",
      enable: true,
      formalName: "",
      id: "EE",
      localName: "",
      nationalFlag: "🇪🇪",
      startChar: "E",
      telephoneCode: "372"
    },
    {
      chineseName: "",
      commonName: "Ethiopia",
      countryCode: "ETH",
      displayName: "埃塞俄比亚",
      enable: true,
      formalName: "",
      id: "ET",
      localName: "",
      nationalFlag: "🇪🇹",
      startChar: "E",
      telephoneCode: "251"
    }
  ],
  F: [
    {
      chineseName: "",
      commonName: "Fiji",
      countryCode: "FJI",
      displayName: "斐济",
      enable: true,
      formalName: "",
      id: "FJ",
      localName: "",
      nationalFlag: "🇫🇯",
      startChar: "F",
      telephoneCode: "679"
    },
    {
      chineseName: "",
      commonName: "Finland",
      countryCode: "FIN",
      displayName: "芬兰",
      enable: true,
      formalName: "",
      id: "FI",
      localName: "",
      nationalFlag: "🇫🇮",
      startChar: "F",
      telephoneCode: "358"
    },
    {
      chineseName: "",
      commonName: "France",
      countryCode: "FRA",
      displayName: "法国",
      enable: true,
      formalName: "",
      id: "FR",
      localName: "",
      nationalFlag: "🇫🇷",
      startChar: "F",
      telephoneCode: "33"
    }
  ],
  G: [
    {
      chineseName: "",
      commonName: "Gabon",
      countryCode: "GAB",
      displayName: "加蓬",
      enable: true,
      formalName: "",
      id: "GA",
      localName: "",
      nationalFlag: "🇬🇦",
      startChar: "G",
      telephoneCode: "241"
    },
    {
      chineseName: "",
      commonName: "Gambia, The",
      countryCode: "GMB",
      displayName: "冈比亚",
      enable: true,
      formalName: "",
      id: "GM",
      localName: "",
      nationalFlag: "🇬🇲",
      startChar: "G",
      telephoneCode: "220"
    },
    {
      chineseName: "",
      commonName: "Georgia",
      countryCode: "GEO",
      displayName: "格鲁吉亚",
      enable: true,
      formalName: "",
      id: "GE",
      localName: "",
      nationalFlag: "🇬🇪",
      startChar: "G",
      telephoneCode: "995"
    },
    {
      chineseName: "",
      commonName: "Germany",
      countryCode: "DEU",
      displayName: "德国",
      enable: true,
      formalName: "",
      id: "DE",
      localName: "",
      nationalFlag: "🇩🇪",
      startChar: "G",
      telephoneCode: "49"
    },
    {
      chineseName: "",
      commonName: "Ghana",
      countryCode: "GHA",
      displayName: "加纳",
      enable: true,
      formalName: "",
      id: "GH",
      localName: "",
      nationalFlag: "🇬🇭",
      startChar: "G",
      telephoneCode: "233"
    },
    {
      chineseName: "",
      commonName: "Greece",
      countryCode: "GRC",
      displayName: "希腊",
      enable: true,
      formalName: "",
      id: "GR",
      localName: "",
      nationalFlag: "🇬🇷",
      startChar: "G",
      telephoneCode: "30"
    },
    {
      chineseName: "",
      commonName: "Grenada",
      countryCode: "GRD",
      displayName: "格林纳达",
      enable: true,
      formalName: "",
      id: "GD",
      localName: "",
      nationalFlag: "🇬🇩",
      startChar: "G",
      telephoneCode: "1473"
    },
    {
      chineseName: "",
      commonName: "Guatemala",
      countryCode: "GTM",
      displayName: "危地马拉",
      enable: true,
      formalName: "",
      id: "GT",
      localName: "",
      nationalFlag: "🇬🇹",
      startChar: "G",
      telephoneCode: "502"
    },
    {
      chineseName: "",
      commonName: "Guinea",
      countryCode: "GIN",
      displayName: "几内亚",
      enable: true,
      formalName: "",
      id: "GN",
      localName: "",
      nationalFlag: "🇬🇳",
      startChar: "G",
      telephoneCode: "224"
    },
    {
      chineseName: "",
      commonName: "Guinea-Bissau",
      countryCode: "GNB",
      displayName: "几内亚比绍",
      enable: true,
      formalName: "",
      id: "GW",
      localName: "",
      nationalFlag: "🇬🇼",
      startChar: "G",
      telephoneCode: "245"
    },
    {
      chineseName: "",
      commonName: "Guyana",
      countryCode: "GUY",
      displayName: "圭亚那",
      enable: true,
      formalName: "",
      id: "GY",
      localName: "",
      nationalFlag: "🇬🇾",
      startChar: "G",
      telephoneCode: "592"
    }
  ],
  H: [
    {
      chineseName: "",
      commonName: "Haiti",
      countryCode: "HTI",
      displayName: "海地",
      enable: true,
      formalName: "",
      id: "HT",
      localName: "",
      nationalFlag: "🇭🇹",
      startChar: "H",
      telephoneCode: "509"
    },
    {
      chineseName: "",
      commonName: "Honduras",
      countryCode: "HND",
      displayName: "洪都拉斯",
      enable: true,
      formalName: "",
      id: "HN",
      localName: "",
      nationalFlag: "🇭🇳",
      startChar: "H",
      telephoneCode: "504"
    },
    {
      chineseName: "",
      commonName: "Hong Kong",
      countryCode: "HKG",
      displayName: "香港,中国",
      enable: true,
      formalName: "",
      id: "HK",
      localName: "",
      nationalFlag: "🇭🇰",
      startChar: "H",
      telephoneCode: "852"
    },
    {
      chineseName: "",
      commonName: "Hungary",
      countryCode: "HUN",
      displayName: "匈牙利",
      enable: true,
      formalName: "",
      id: "HU",
      localName: "",
      nationalFlag: "🇭🇺",
      startChar: "H",
      telephoneCode: "36"
    }
  ],
  I: [
    {
      chineseName: "",
      commonName: "Iceland",
      countryCode: "ISL",
      displayName: "冰岛",
      enable: true,
      formalName: "",
      id: "IS",
      localName: "",
      nationalFlag: "🇮🇸",
      startChar: "I",
      telephoneCode: "354"
    },
    {
      chineseName: "",
      commonName: "India",
      countryCode: "IND",
      displayName: "印度",
      enable: true,
      formalName: "",
      id: "IN",
      localName: "",
      nationalFlag: "🇮🇳",
      startChar: "I",
      telephoneCode: "91"
    },
    {
      chineseName: "",
      commonName: "Indonesia",
      countryCode: "IDN",
      displayName: "印度尼西亚",
      enable: true,
      formalName: "",
      id: "ID",
      localName: "",
      nationalFlag: "🇮🇩",
      startChar: "I",
      telephoneCode: "62"
    },
    {
      chineseName: "",
      commonName: "Iran",
      countryCode: "IRN",
      displayName: "伊朗（伊斯兰共和国）",
      enable: true,
      formalName: "",
      id: "IR",
      localName: "",
      nationalFlag: "🇮🇷",
      startChar: "I",
      telephoneCode: "98"
    },
    {
      chineseName: "",
      commonName: "Iraq",
      countryCode: "IRQ",
      displayName: "伊拉克",
      enable: true,
      formalName: "",
      id: "IQ",
      localName: "",
      nationalFlag: "🇮🇶",
      startChar: "I",
      telephoneCode: "964"
    },
    {
      chineseName: "",
      commonName: "Ireland",
      countryCode: "IRL",
      displayName: "爱尔兰",
      enable: true,
      formalName: "",
      id: "IE",
      localName: "",
      nationalFlag: "🇮🇪",
      startChar: "I",
      telephoneCode: "353"
    },
    {
      chineseName: "",
      commonName: "Israel",
      countryCode: "ISR",
      displayName: "以色列",
      enable: true,
      formalName: "",
      id: "IL",
      localName: "",
      nationalFlag: "🇮🇱",
      startChar: "I",
      telephoneCode: "972"
    },
    {
      chineseName: "",
      commonName: "Italy",
      countryCode: "ITA",
      displayName: "意大利",
      enable: true,
      formalName: "",
      id: "IT",
      localName: "",
      nationalFlag: "🇮🇹",
      startChar: "I",
      telephoneCode: "39"
    }
  ],
  J: [
    {
      chineseName: "",
      commonName: "Jamaica",
      countryCode: "JAM",
      displayName: "牙买加",
      enable: true,
      formalName: "",
      id: "JM",
      localName: "",
      nationalFlag: "🇯🇲",
      startChar: "J",
      telephoneCode: "1876"
    },
    {
      chineseName: "",
      commonName: "Japan",
      countryCode: "JPN",
      displayName: "日本",
      enable: true,
      formalName: "",
      id: "JP",
      localName: "",
      nationalFlag: "🇯🇵",
      startChar: "J",
      telephoneCode: "81"
    },
    {
      chineseName: "",
      commonName: "Jordan",
      countryCode: "JOR",
      displayName: "约旦",
      enable: true,
      formalName: "",
      id: "JO",
      localName: "",
      nationalFlag: "🇯🇴",
      startChar: "J",
      telephoneCode: "962"
    }
  ],
  K: [
    {
      chineseName: "",
      commonName: "Kazakhstan",
      countryCode: "KAZ",
      displayName: "哈萨克斯坦",
      enable: true,
      formalName: "",
      id: "KZ",
      localName: "",
      nationalFlag: "🇰🇿",
      startChar: "K",
      telephoneCode: "7"
    },
    {
      chineseName: "",
      commonName: "Kenya",
      countryCode: "KEN",
      displayName: "肯尼亚",
      enable: true,
      formalName: "",
      id: "KE",
      localName: "",
      nationalFlag: "🇰🇪",
      startChar: "K",
      telephoneCode: "254"
    },
    {
      chineseName: "",
      commonName: "Kiribati",
      countryCode: "KIR",
      displayName: "基里巴斯",
      enable: true,
      formalName: "",
      id: "KI",
      localName: "",
      nationalFlag: "🇰🇮",
      startChar: "K",
      telephoneCode: "686"
    },
    {
      chineseName: "",
      commonName: "Korea, North",
      countryCode: "PRK",
      displayName: "朝鲜（朝鲜民主主义人民共和国）",
      enable: true,
      formalName: "",
      id: "KP",
      localName: "",
      nationalFlag: "🇰🇵",
      startChar: "K",
      telephoneCode: "850"
    },
    {
      chineseName: "",
      commonName: "Kosovo",
      countryCode: "",
      displayName: "科索沃",
      enable: true,
      formalName: "",
      id: "YK",
      localName: "",
      nationalFlag: "🇽🇰",
      startChar: "K",
      telephoneCode: "383"
    },
    {
      chineseName: "",
      commonName: "Kuwait",
      countryCode: "KWT",
      displayName: "科威特",
      enable: true,
      formalName: "",
      id: "KW",
      localName: "",
      nationalFlag: "🇰🇼",
      startChar: "K",
      telephoneCode: "965"
    },
    {
      chineseName: "",
      commonName: "Kyrgyzstan",
      countryCode: "KGZ",
      displayName: "吉尔吉斯斯坦",
      enable: true,
      formalName: "",
      id: "KG",
      localName: "",
      nationalFlag: "🇰🇬",
      startChar: "K",
      telephoneCode: "996"
    }
  ],
  L: [
    {
      chineseName: "",
      commonName: "Laos",
      countryCode: "LAO",
      displayName: "老挝人民民主共和国",
      enable: true,
      formalName: "",
      id: "LA",
      localName: "",
      nationalFlag: "🇱🇦",
      startChar: "L",
      telephoneCode: "856"
    },
    {
      chineseName: "",
      commonName: "Latvia",
      countryCode: "LVA",
      displayName: "拉脱维亚",
      enable: true,
      formalName: "",
      id: "LV",
      localName: "",
      nationalFlag: "🇱🇻",
      startChar: "L",
      telephoneCode: "371"
    },
    {
      chineseName: "",
      commonName: "Lebanon",
      countryCode: "LBN",
      displayName: "黎巴嫩",
      enable: true,
      formalName: "",
      id: "LB",
      localName: "",
      nationalFlag: "🇱🇧",
      startChar: "L",
      telephoneCode: "961"
    },
    {
      chineseName: "",
      commonName: "Lesotho",
      countryCode: "LSO",
      displayName: "莱索托",
      enable: true,
      formalName: "",
      id: "LS",
      localName: "",
      nationalFlag: "🇱🇸",
      startChar: "L",
      telephoneCode: "266"
    },
    {
      chineseName: "",
      commonName: "Liberia",
      countryCode: "LBR",
      displayName: "利比里亚",
      enable: true,
      formalName: "",
      id: "LR",
      localName: "",
      nationalFlag: "🇱🇷",
      startChar: "L",
      telephoneCode: "231"
    },
    {
      chineseName: "",
      commonName: "Libya",
      countryCode: "LBY",
      displayName: "利比亚",
      enable: true,
      formalName: "",
      id: "LY",
      localName: "",
      nationalFlag: "🇱🇾",
      startChar: "L",
      telephoneCode: "218"
    },
    {
      chineseName: "",
      commonName: "Liechtenstein",
      countryCode: "LIE",
      displayName: "列支敦士登",
      enable: true,
      formalName: "",
      id: "LI",
      localName: "",
      nationalFlag: "🇱🇮",
      startChar: "L",
      telephoneCode: "423"
    },
    {
      chineseName: "",
      commonName: "Lithuania",
      countryCode: "LTU",
      displayName: "立陶宛",
      enable: true,
      formalName: "",
      id: "LT",
      localName: "",
      nationalFlag: "🇱🇹",
      startChar: "L",
      telephoneCode: "370"
    },
    {
      chineseName: "",
      commonName: "Luxembourg",
      countryCode: "LUX",
      displayName: "卢森堡",
      enable: true,
      formalName: "",
      id: "LU",
      localName: "",
      nationalFlag: "🇱🇺",
      startChar: "L",
      telephoneCode: "352"
    }
  ],
  M: [
    {
      chineseName: "",
      commonName: "Macau",
      countryCode: "MAC",
      displayName: "澳门,中国",
      enable: true,
      formalName: "",
      id: "MO",
      localName: "",
      nationalFlag: "🇲🇴",
      startChar: "M",
      telephoneCode: "853"
    },
    {
      chineseName: "",
      commonName: "Macedonia",
      countryCode: "MKD",
      displayName: "马其顿共和国",
      enable: true,
      formalName: "",
      id: "MK",
      localName: "",
      nationalFlag: "🇲🇰",
      startChar: "M",
      telephoneCode: "389"
    },
    {
      chineseName: "",
      commonName: "Madagascar",
      countryCode: "MDG",
      displayName: "马达加斯加",
      enable: true,
      formalName: "",
      id: "MG",
      localName: "",
      nationalFlag: "🇲🇬",
      startChar: "M",
      telephoneCode: "261"
    },
    {
      chineseName: "",
      commonName: "Malawi",
      countryCode: "MWI",
      displayName: "马拉维",
      enable: true,
      formalName: "",
      id: "MW",
      localName: "",
      nationalFlag: "🇲🇼",
      startChar: "M",
      telephoneCode: "265"
    },
    {
      chineseName: "",
      commonName: "Malaysia",
      countryCode: "MYS",
      displayName: "马来西亚",
      enable: true,
      formalName: "",
      id: "MY",
      localName: "",
      nationalFlag: "🇲🇾",
      startChar: "M",
      telephoneCode: "60"
    },
    {
      chineseName: "",
      commonName: "Maldives",
      countryCode: "MDV",
      displayName: "马尔代夫",
      enable: true,
      formalName: "",
      id: "MV",
      localName: "",
      nationalFlag: "🇲🇻",
      startChar: "M",
      telephoneCode: "960"
    },
    {
      chineseName: "",
      commonName: "Mali",
      countryCode: "MLI",
      displayName: "马里",
      enable: true,
      formalName: "",
      id: "ML",
      localName: "",
      nationalFlag: "🇲🇱",
      startChar: "M",
      telephoneCode: "223"
    },
    {
      chineseName: "",
      commonName: "Malta",
      countryCode: "MLT",
      displayName: "马耳他",
      enable: true,
      formalName: "",
      id: "MT",
      localName: "",
      nationalFlag: "🇲🇹",
      startChar: "M",
      telephoneCode: "356"
    },
    {
      chineseName: "",
      commonName: "Marshall Islands",
      countryCode: "MHL",
      displayName: "马绍尔群岛",
      enable: true,
      formalName: "",
      id: "MH",
      localName: "",
      nationalFlag: "🇲🇭",
      startChar: "M",
      telephoneCode: "692"
    },
    {
      chineseName: "",
      commonName: "Mauritania",
      countryCode: "MRT",
      displayName: "毛里塔尼亚",
      enable: true,
      formalName: "",
      id: "MR",
      localName: "",
      nationalFlag: "🇲🇷",
      startChar: "M",
      telephoneCode: "222"
    },
    {
      chineseName: "",
      commonName: "Mauritius",
      countryCode: "MUS",
      displayName: "毛里求斯",
      enable: true,
      formalName: "",
      id: "MU",
      localName: "",
      nationalFlag: "🇲🇺",
      startChar: "M",
      telephoneCode: "230"
    },
    {
      chineseName: "",
      commonName: "Mexico",
      countryCode: "MEX",
      displayName: "墨西哥",
      enable: true,
      formalName: "",
      id: "MX",
      localName: "",
      nationalFlag: "🇲🇽",
      startChar: "M",
      telephoneCode: "52"
    },
    {
      chineseName: "",
      commonName: "Micronesia",
      countryCode: "FSM",
      displayName: "密克罗尼西亚（联邦）",
      enable: true,
      formalName: "",
      id: "FM",
      localName: "",
      nationalFlag: "🇫🇲",
      startChar: "M",
      telephoneCode: "691"
    },
    {
      chineseName: "",
      commonName: "Moldova",
      countryCode: "MDA",
      displayName: "摩尔多瓦共和国",
      enable: true,
      formalName: "",
      id: "MD",
      localName: "",
      nationalFlag: "🇲🇩",
      startChar: "M",
      telephoneCode: "373"
    },
    {
      chineseName: "",
      commonName: "Monaco",
      countryCode: "MCO",
      displayName: "摩纳哥",
      enable: true,
      formalName: "",
      id: "MC",
      localName: "",
      nationalFlag: "🇲🇨",
      startChar: "M",
      telephoneCode: "377"
    },
    {
      chineseName: "",
      commonName: "Mongolia",
      countryCode: "MNG",
      displayName: "蒙古",
      enable: true,
      formalName: "",
      id: "MN",
      localName: "",
      nationalFlag: "🇲🇳",
      startChar: "M",
      telephoneCode: "976"
    },
    {
      chineseName: "",
      commonName: "Montenegro",
      countryCode: "MNE",
      displayName: "黑山",
      enable: true,
      formalName: "",
      id: "ME",
      localName: "",
      nationalFlag: "🇲🇪",
      startChar: "M",
      telephoneCode: "382"
    },
    {
      chineseName: "",
      commonName: "Morocco",
      countryCode: "MAR",
      displayName: "摩洛哥",
      enable: true,
      formalName: "",
      id: "MA",
      localName: "",
      nationalFlag: "🇲🇦",
      startChar: "M",
      telephoneCode: "212"
    },
    {
      chineseName: "",
      commonName: "Mozambique",
      countryCode: "MOZ",
      displayName: "莫桑比克",
      enable: true,
      formalName: "",
      id: "MZ",
      localName: "",
      nationalFlag: "🇲🇿",
      startChar: "M",
      telephoneCode: "258"
    },
    {
      chineseName: "",
      commonName: "Myanmar (Burma)",
      countryCode: "MMR",
      displayName: "缅甸",
      enable: true,
      formalName: "",
      id: "MM",
      localName: "",
      nationalFlag: "🇲🇲",
      startChar: "M",
      telephoneCode: "95"
    }
  ],
  N: [
    {
      chineseName: "",
      commonName: "Namibia",
      countryCode: "NAM",
      displayName: "纳米比亚",
      enable: true,
      formalName: "",
      id: "NA",
      localName: "",
      nationalFlag: "🇳🇦",
      startChar: "N",
      telephoneCode: "264"
    },
    {
      chineseName: "",
      commonName: "Nauru",
      countryCode: "NRU",
      displayName: "瑙鲁",
      enable: true,
      formalName: "",
      id: "NR",
      localName: "",
      nationalFlag: "🇳🇷",
      startChar: "N",
      telephoneCode: "674"
    },
    {
      chineseName: "",
      commonName: "Nepal",
      countryCode: "NPL",
      displayName: "尼泊尔",
      enable: true,
      formalName: "",
      id: "NP",
      localName: "",
      nationalFlag: "🇳🇵",
      startChar: "N",
      telephoneCode: "977"
    },
    {
      chineseName: "",
      commonName: "Netherlands",
      countryCode: "NLD",
      displayName: "荷兰",
      enable: true,
      formalName: "",
      id: "NL",
      localName: "",
      nationalFlag: "🇳🇱",
      startChar: "N",
      telephoneCode: "31"
    },
    {
      chineseName: "",
      commonName: "New Caledonia",
      countryCode: "NCL",
      displayName: "新喀里多尼亚",
      enable: true,
      formalName: "",
      id: "NC",
      localName: "",
      nationalFlag: "🇳🇨",
      startChar: "N",
      telephoneCode: "687"
    },
    {
      chineseName: "",
      commonName: "New Zealand",
      countryCode: "NZL",
      displayName: "新西兰",
      enable: true,
      formalName: "",
      id: "NZ",
      localName: "",
      nationalFlag: "🇳🇿",
      startChar: "N",
      telephoneCode: "64"
    },
    {
      chineseName: "",
      commonName: "Nicaragua",
      countryCode: "NIC",
      displayName: "尼加拉瓜",
      enable: true,
      formalName: "",
      id: "NI",
      localName: "",
      nationalFlag: "🇳🇮",
      startChar: "N",
      telephoneCode: "505"
    },
    {
      chineseName: "",
      commonName: "Niger",
      countryCode: "NER",
      displayName: "尼日尔",
      enable: true,
      formalName: "",
      id: "NE",
      localName: "",
      nationalFlag: "🇳🇪",
      startChar: "N",
      telephoneCode: "227"
    },
    {
      chineseName: "",
      commonName: "Nigeria",
      countryCode: "NGA",
      displayName: "尼日利亚",
      enable: true,
      formalName: "",
      id: "NG",
      localName: "",
      nationalFlag: "🇳🇬",
      startChar: "N",
      telephoneCode: "234"
    },
    {
      chineseName: "",
      commonName: "Norway",
      countryCode: "NOR",
      displayName: "挪威",
      enable: true,
      formalName: "",
      id: "NO",
      localName: "",
      nationalFlag: "🇳🇴",
      startChar: "N",
      telephoneCode: "47"
    }
  ],
  O: [
    {
      chineseName: "",
      commonName: "Oman",
      countryCode: "OMN",
      displayName: "阿曼",
      enable: true,
      formalName: "",
      id: "OM",
      localName: "",
      nationalFlag: "🇴🇲",
      startChar: "O",
      telephoneCode: "968"
    }
  ],
  P: [
    {
      chineseName: "",
      commonName: "Pakistan",
      countryCode: "PAK",
      displayName: "巴基斯坦",
      enable: true,
      formalName: "",
      id: "PK",
      localName: "",
      nationalFlag: "🇵🇰",
      startChar: "P",
      telephoneCode: "92"
    },
    {
      chineseName: "",
      commonName: "Palau",
      countryCode: "PLW",
      displayName: "帛琉",
      enable: true,
      formalName: "",
      id: "PW",
      localName: "",
      nationalFlag: "🇵🇼",
      startChar: "P",
      telephoneCode: "680"
    },
    {
      chineseName: "",
      commonName: "Palestine",
      countryCode: "PSE",
      displayName: "巴勒斯坦",
      enable: true,
      formalName: "",
      id: "PS",
      localName: "",
      nationalFlag: "🇵🇸",
      startChar: "P",
      telephoneCode: "970"
    },
    {
      chineseName: "",
      commonName: "Panama",
      countryCode: "PAN",
      displayName: "巴拿马",
      enable: true,
      formalName: "",
      id: "PA",
      localName: "",
      nationalFlag: "🇵🇦",
      startChar: "P",
      telephoneCode: "507"
    },
    {
      chineseName: "",
      commonName: "Papua New Guinea",
      countryCode: "PNG",
      displayName: "巴布亚新几内亚",
      enable: true,
      formalName: "",
      id: "PG",
      localName: "",
      nationalFlag: "🇵🇬",
      startChar: "P",
      telephoneCode: "675"
    },
    {
      chineseName: "",
      commonName: "Paraguay",
      countryCode: "PRY",
      displayName: "巴拉圭",
      enable: true,
      formalName: "",
      id: "PY",
      localName: "",
      nationalFlag: "🇵🇾",
      startChar: "P",
      telephoneCode: "595"
    },
    {
      chineseName: "",
      commonName: "Peru",
      countryCode: "PER",
      displayName: "秘鲁",
      enable: true,
      formalName: "",
      id: "PE",
      localName: "",
      nationalFlag: "🇵🇪",
      startChar: "P",
      telephoneCode: "51"
    },
    {
      chineseName: "",
      commonName: "Philippines",
      countryCode: "PHL",
      displayName: "菲律宾",
      enable: true,
      formalName: "",
      id: "PH",
      localName: "",
      nationalFlag: "🇵🇭",
      startChar: "P",
      telephoneCode: "63"
    },
    {
      chineseName: "",
      commonName: "Poland",
      countryCode: "POL",
      displayName: "波兰",
      enable: true,
      formalName: "",
      id: "PL",
      localName: "",
      nationalFlag: "🇵🇱",
      startChar: "P",
      telephoneCode: "48"
    },
    {
      chineseName: "",
      commonName: "Portugal",
      countryCode: "PRT",
      displayName: "葡萄牙",
      enable: true,
      formalName: "",
      id: "PT",
      localName: "",
      nationalFlag: "🇵🇹",
      startChar: "P",
      telephoneCode: "351"
    }
  ],
  Q: [
    {
      chineseName: "",
      commonName: "Qatar",
      countryCode: "QAT",
      displayName: "卡塔尔",
      enable: true,
      formalName: "",
      id: "QA",
      localName: "",
      nationalFlag: "🇶🇦",
      startChar: "Q",
      telephoneCode: "974"
    }
  ],
  R: [
    {
      chineseName: "",
      commonName: "Reunion",
      countryCode: "REU",
      displayName: "留尼旺岛",
      enable: true,
      formalName: "",
      id: "RE",
      localName: "",
      nationalFlag: "🇷🇪",
      startChar: "R",
      telephoneCode: "262"
    },
    {
      chineseName: "",
      commonName: "Romania",
      countryCode: "ROU",
      displayName: "罗马尼亚",
      enable: true,
      formalName: "",
      id: "RO",
      localName: "",
      nationalFlag: "🇷🇴",
      startChar: "R",
      telephoneCode: "40"
    },
    {
      chineseName: "",
      commonName: "Russia",
      countryCode: "RUS",
      displayName: "俄罗斯",
      enable: true,
      formalName: "",
      id: "RU",
      localName: "",
      nationalFlag: "🇷🇺",
      startChar: "R",
      telephoneCode: "7"
    },
    {
      chineseName: "",
      commonName: "Rwanda",
      countryCode: "RWA",
      displayName: "卢旺达",
      enable: true,
      formalName: "",
      id: "RW",
      localName: "",
      nationalFlag: "🇷🇼",
      startChar: "R",
      telephoneCode: "250"
    }
  ],
  S: [
    {
      chineseName: "",
      commonName: "Saint Kitts and Nevis",
      countryCode: "KNA",
      displayName: "圣基茨和尼维斯",
      enable: true,
      formalName: "",
      id: "KN",
      localName: "",
      nationalFlag: "🇰🇳",
      startChar: "S",
      telephoneCode: "1869"
    },
    {
      chineseName: "",
      commonName: "Saint Lucia",
      countryCode: "LCA",
      displayName: "圣卢西亚",
      enable: true,
      formalName: "",
      id: "LC",
      localName: "",
      nationalFlag: "🇱🇨",
      startChar: "S",
      telephoneCode: "1758"
    },
    {
      chineseName: "",
      commonName: "Saint Vincent and the Grenadines",
      countryCode: "VCT",
      displayName: "圣文森特和格林纳丁斯",
      enable: true,
      formalName: "",
      id: "VC",
      localName: "",
      nationalFlag: "🇻🇨",
      startChar: "S",
      telephoneCode: "1784"
    },
    {
      chineseName: "",
      commonName: "Samoa",
      countryCode: "WSM",
      displayName: "萨摩亚",
      enable: true,
      formalName: "",
      id: "WS",
      localName: "",
      nationalFlag: "🇼🇸",
      startChar: "S",
      telephoneCode: "685"
    },
    {
      chineseName: "",
      commonName: "San Marino",
      countryCode: "SMR",
      displayName: "圣马力诺",
      enable: true,
      formalName: "",
      id: "SM",
      localName: "",
      nationalFlag: "🇸🇲",
      startChar: "S",
      telephoneCode: "378"
    },
    {
      chineseName: "",
      commonName: "Sao Tome and Principe",
      countryCode: "STP",
      displayName: "圣多美和普林西比",
      enable: true,
      formalName: "",
      id: "ST",
      localName: "",
      nationalFlag: "🇸🇹",
      startChar: "S",
      telephoneCode: "239"
    },
    {
      chineseName: "",
      commonName: "Saudi Arabia",
      countryCode: "SAU",
      displayName: "沙特阿拉伯",
      enable: true,
      formalName: "",
      id: "SA",
      localName: "",
      nationalFlag: "🇸🇦",
      startChar: "S",
      telephoneCode: "966"
    },
    {
      chineseName: "",
      commonName: "Senegal",
      countryCode: "SEN",
      displayName: "塞内加尔",
      enable: true,
      formalName: "",
      id: "SN",
      localName: "",
      nationalFlag: "🇸🇳",
      startChar: "S",
      telephoneCode: "221"
    },
    {
      chineseName: "",
      commonName: "Serbia",
      countryCode: "SRB",
      displayName: "塞尔维亚",
      enable: true,
      formalName: "",
      id: "RS",
      localName: "",
      nationalFlag: "🇷🇸",
      startChar: "S",
      telephoneCode: "381"
    },
    {
      chineseName: "",
      commonName: "Seychelles",
      countryCode: "SYC",
      displayName: "塞舌尔",
      enable: true,
      formalName: "",
      id: "SC",
      localName: "",
      nationalFlag: "🇸🇨",
      startChar: "S",
      telephoneCode: "248"
    },
    {
      chineseName: "",
      commonName: "Sierra Leone",
      countryCode: "SLE",
      displayName: "塞拉利昂",
      enable: true,
      formalName: "",
      id: "SL",
      localName: "",
      nationalFlag: "🇸🇱",
      startChar: "S",
      telephoneCode: "232"
    },
    {
      chineseName: "",
      commonName: "Singapore",
      countryCode: "SGP",
      displayName: "新加坡",
      enable: true,
      formalName: "",
      id: "SG",
      localName: "",
      nationalFlag: "🇸🇬",
      startChar: "S",
      telephoneCode: "65"
    },
    {
      chineseName: "",
      commonName: "Slovakia",
      countryCode: "SVK",
      displayName: "斯洛伐克",
      enable: true,
      formalName: "",
      id: "SK",
      localName: "",
      nationalFlag: "🇸🇰",
      startChar: "S",
      telephoneCode: "421"
    },
    {
      chineseName: "",
      commonName: "Slovenia",
      countryCode: "SVN",
      displayName: "斯洛文尼亚",
      enable: true,
      formalName: "",
      id: "SI",
      localName: "",
      nationalFlag: "🇸🇮",
      startChar: "S",
      telephoneCode: "386"
    },
    {
      chineseName: "",
      commonName: "Solomon Islands",
      countryCode: "SLB",
      displayName: "所罗门群岛",
      enable: true,
      formalName: "",
      id: "SB",
      localName: "",
      nationalFlag: "🇸🇧",
      startChar: "S",
      telephoneCode: "677"
    },
    {
      chineseName: "",
      commonName: "Somalia",
      countryCode: "SOM",
      displayName: "索马里",
      enable: true,
      formalName: "",
      id: "SO",
      localName: "",
      nationalFlag: "🇸🇴",
      startChar: "S",
      telephoneCode: "252"
    },
    {
      chineseName: "",
      commonName: "South Africa",
      countryCode: "ZAF",
      displayName: "南非",
      enable: true,
      formalName: "",
      id: "ZA",
      localName: "",
      nationalFlag: "🇿🇦",
      startChar: "S",
      telephoneCode: "27"
    },
    {
      chineseName: "",
      commonName: "South Korea",
      countryCode: "KOR",
      displayName: "韩国",
      enable: true,
      formalName: "",
      id: "KR",
      localName: "",
      nationalFlag: "🇰🇷",
      startChar: "S",
      telephoneCode: "82"
    },
    {
      chineseName: "",
      commonName: "South Sudan",
      countryCode: "SSD",
      displayName: "南苏丹",
      enable: true,
      formalName: "",
      id: "SS",
      localName: "",
      nationalFlag: "🇸🇸",
      startChar: "S",
      telephoneCode: "211"
    },
    {
      chineseName: "",
      commonName: "Spain",
      countryCode: "ESP",
      displayName: "西班牙",
      enable: true,
      formalName: "",
      id: "ES",
      localName: "",
      nationalFlag: "🇪🇸",
      startChar: "S",
      telephoneCode: "34"
    },
    {
      chineseName: "",
      commonName: "Sri Lanka",
      countryCode: "LKA",
      displayName: "斯里兰卡",
      enable: true,
      formalName: "",
      id: "LK",
      localName: "",
      nationalFlag: "🇱🇰",
      startChar: "S",
      telephoneCode: "94"
    },
    {
      chineseName: "",
      commonName: "Sudan",
      countryCode: "SDN",
      displayName: "苏丹",
      enable: true,
      formalName: "",
      id: "SD",
      localName: "",
      nationalFlag: "🇸🇩",
      startChar: "S",
      telephoneCode: "249"
    },
    {
      chineseName: "",
      commonName: "Suriname",
      countryCode: "SUR",
      displayName: "苏里南",
      enable: true,
      formalName: "",
      id: "SR",
      localName: "",
      nationalFlag: "🇸🇷",
      startChar: "S",
      telephoneCode: "597"
    },
    {
      chineseName: "",
      commonName: "Swaziland",
      countryCode: "SWZ",
      displayName: "斯威士兰",
      enable: true,
      formalName: "",
      id: "SZ",
      localName: "",
      nationalFlag: "🇸🇿",
      startChar: "S",
      telephoneCode: "268"
    },
    {
      chineseName: "",
      commonName: "Sweden",
      countryCode: "SWE",
      displayName: "瑞典",
      enable: true,
      formalName: "",
      id: "SE",
      localName: "",
      nationalFlag: "🇸🇪",
      startChar: "S",
      telephoneCode: "46"
    },
    {
      chineseName: "",
      commonName: "Switzerland",
      countryCode: "CHE",
      displayName: "瑞士",
      enable: true,
      formalName: "",
      id: "CH",
      localName: "",
      nationalFlag: "🇨🇭",
      startChar: "S",
      telephoneCode: "41"
    },
    {
      chineseName: "",
      commonName: "Syria",
      countryCode: "SYR",
      displayName: "阿拉伯叙利亚共和国",
      enable: true,
      formalName: "",
      id: "SY",
      localName: "",
      nationalFlag: "🇸🇾",
      startChar: "S",
      telephoneCode: "963"
    }
  ],
  T: [
    {
      chineseName: "",
      commonName: "Taiwan",
      countryCode: "TWN",
      displayName: "台湾,中国",
      enable: true,
      formalName: "",
      id: "TW",
      localName: "",
      nationalFlag: tw,
      flagIsImage: true,
      startChar: "T",
      telephoneCode: "886"
    },
    {
      chineseName: "",
      commonName: "Tajikistan",
      countryCode: "TJK",
      displayName: "塔吉克斯坦",
      enable: true,
      formalName: "",
      id: "TJ",
      localName: "",
      nationalFlag: "🇹🇯",
      startChar: "T",
      telephoneCode: "992"
    },
    {
      chineseName: "",
      commonName: "Tanzania",
      countryCode: "TZA",
      displayName: "坦桑尼亚联合共和国",
      enable: true,
      formalName: "",
      id: "TZ",
      localName: "",
      nationalFlag: "🇹🇿",
      startChar: "T",
      telephoneCode: "255"
    },
    {
      chineseName: "",
      commonName: "Thailand",
      countryCode: "THA",
      displayName: "泰国",
      enable: true,
      formalName: "",
      id: "TH",
      localName: "",
      nationalFlag: "🇹🇭",
      startChar: "T",
      telephoneCode: "66"
    },
    {
      chineseName: "",
      commonName: "The British Overseas Territory of Gibraltar",
      countryCode: "GIB",
      displayName: "直布罗陀",
      enable: true,
      formalName: "",
      id: "GI",
      localName: "",
      nationalFlag: "🇬🇮",
      startChar: "T",
      telephoneCode: "350"
    },
    {
      chineseName: "",
      commonName: "The Cook Islands",
      countryCode: "COK",
      displayName: "库克群岛",
      enable: true,
      formalName: "",
      id: "CK",
      localName: "",
      nationalFlag: "🇨🇰",
      startChar: "T",
      telephoneCode: "682"
    },
    {
      chineseName: "",
      commonName: "Timor-Leste (East Timor)",
      countryCode: "TLS",
      displayName: "东帝汶",
      enable: true,
      formalName: "",
      id: "TL",
      localName: "",
      nationalFlag: "🇹🇱",
      startChar: "T",
      telephoneCode: "670"
    },
    {
      chineseName: "",
      commonName: "Togo",
      countryCode: "TGO",
      displayName: "多哥",
      enable: true,
      formalName: "",
      id: "TG",
      localName: "",
      nationalFlag: "🇹🇬",
      startChar: "T",
      telephoneCode: "228"
    },
    {
      chineseName: "",
      commonName: "Tonga",
      countryCode: "TON",
      displayName: "汤加",
      enable: true,
      formalName: "",
      id: "TO",
      localName: "",
      nationalFlag: "🇹🇴",
      startChar: "T",
      telephoneCode: "676"
    },
    {
      chineseName: "",
      commonName: "Trinidad and Tobago",
      countryCode: "TTO",
      displayName: "特立尼达和多巴哥",
      enable: true,
      formalName: "",
      id: "TT",
      localName: "",
      nationalFlag: "🇹🇹",
      startChar: "T",
      telephoneCode: "1868"
    },
    {
      chineseName: "",
      commonName: "Tunisia",
      countryCode: "TUN",
      displayName: "突尼斯",
      enable: true,
      formalName: "",
      id: "TN",
      localName: "",
      nationalFlag: "🇹🇳",
      startChar: "T",
      telephoneCode: "216"
    },
    {
      chineseName: "",
      commonName: "Turkey",
      countryCode: "TUR",
      displayName: "土耳其",
      enable: true,
      formalName: "",
      id: "TR",
      localName: "",
      nationalFlag: "🇹🇷",
      startChar: "T",
      telephoneCode: "90"
    },
    {
      chineseName: "",
      commonName: "Turkmenistan",
      countryCode: "TKM",
      displayName: "土库曼斯坦",
      enable: true,
      formalName: "",
      id: "TM",
      localName: "",
      nationalFlag: "🇹🇲",
      startChar: "T",
      telephoneCode: "993"
    },
    {
      chineseName: "",
      commonName: "Tuvalu",
      countryCode: "TUV",
      displayName: "图瓦卢",
      enable: true,
      formalName: "",
      id: "TV",
      localName: "",
      nationalFlag: "🇹🇻",
      startChar: "T",
      telephoneCode: "688"
    }
  ],
  U: [
    {
      chineseName: "",
      commonName: "U.S. Virgin Islands",
      countryCode: "VIR",
      displayName: "维尔京群岛（美属）",
      enable: true,
      formalName: "",
      id: "VI",
      localName: "",
      nationalFlag: "🇻🇮",
      startChar: "U",
      telephoneCode: "1340"
    },
    {
      chineseName: "",
      commonName: "Uganda",
      countryCode: "UGA",
      displayName: "乌干达",
      enable: true,
      formalName: "",
      id: "UG",
      localName: "",
      nationalFlag: "🇺🇬",
      startChar: "U",
      telephoneCode: "256"
    },
    {
      chineseName: "",
      commonName: "Ukraine",
      countryCode: "UKR",
      displayName: "乌克兰",
      enable: true,
      formalName: "",
      id: "UA",
      localName: "",
      nationalFlag: "🇺🇦",
      startChar: "U",
      telephoneCode: "380"
    },
    {
      chineseName: "",
      commonName: "United Arab Emirates",
      countryCode: "ARE",
      displayName: "阿联酋",
      enable: true,
      formalName: "",
      id: "AE",
      localName: "",
      nationalFlag: "🇦🇪",
      startChar: "U",
      telephoneCode: "971"
    },
    {
      chineseName: "",
      commonName: "United Kingdom",
      countryCode: "GBR",
      displayName: "英国",
      enable: true,
      formalName: "",
      id: "GB",
      localName: "",
      nationalFlag: "🇬🇧",
      startChar: "U",
      telephoneCode: "44"
    },
    {
      chineseName: "",
      commonName: "United States",
      countryCode: "USA",
      displayName: "美国",
      enable: true,
      formalName: "",
      id: "US",
      localName: "",
      nationalFlag: "🇺🇸",
      startChar: "U",
      telephoneCode: "1"
    },
    {
      chineseName: "",
      commonName: "Uruguay",
      countryCode: "URY",
      displayName: "乌拉圭",
      enable: true,
      formalName: "",
      id: "UY",
      localName: "",
      nationalFlag: "🇺🇾",
      startChar: "U",
      telephoneCode: "598"
    },
    {
      chineseName: "",
      commonName: "Uzbekistan",
      countryCode: "UZB",
      displayName: "乌兹别克斯坦",
      enable: true,
      formalName: "",
      id: "UZ",
      localName: "",
      nationalFlag: "🇺🇿",
      startChar: "U",
      telephoneCode: "998"
    }
  ],
  V: [
    {
      chineseName: "",
      commonName: "Vanuatu",
      countryCode: "VUT",
      displayName: "瓦努阿图",
      enable: true,
      formalName: "",
      id: "VU",
      localName: "",
      nationalFlag: "🇻🇺",
      startChar: "V",
      telephoneCode: "678"
    },
    {
      chineseName: "",
      commonName: "Vatican City",
      countryCode: "VAT",
      displayName: "罗马教廷",
      enable: true,
      formalName: "",
      id: "VA",
      localName: "",
      nationalFlag: "🇻🇦",
      startChar: "V",
      telephoneCode: "379"
    },
    {
      chineseName: "",
      commonName: "Venezuela",
      countryCode: "VEN",
      displayName: "委内瑞拉玻利瓦尔共和国",
      enable: true,
      formalName: "",
      id: "VE",
      localName: "",
      nationalFlag: "🇻🇪",
      startChar: "V",
      telephoneCode: "58"
    },
    {
      chineseName: "",
      commonName: "Vietnam",
      countryCode: "VNM",
      displayName: "越南",
      enable: true,
      formalName: "",
      id: "VN",
      localName: "",
      nationalFlag: "🇻🇳",
      startChar: "V",
      telephoneCode: "84"
    }
  ],
  Y: [
    {
      chineseName: "",
      commonName: "Yemen",
      countryCode: "YEM",
      displayName: "也门",
      enable: true,
      formalName: "",
      id: "YE",
      localName: "",
      nationalFlag: "🇾🇪",
      startChar: "Y",
      telephoneCode: "967"
    }
  ],
  Z: [
    {
      chineseName: "",
      commonName: "Zambia",
      countryCode: "ZMB",
      displayName: "赞比亚",
      enable: true,
      formalName: "",
      id: "ZM",
      localName: "",
      nationalFlag: "🇿🇲",
      startChar: "Z",
      telephoneCode: "260"
    },
    {
      chineseName: "",
      commonName: "Zimbabwe",
      countryCode: "ZWE",
      displayName: "津巴布韦",
      enable: true,
      formalName: "",
      id: "ZW",
      localName: "",
      nationalFlag: "🇿🇼",
      startChar: "Z",
      telephoneCode: "263"
    }
  ]
};

let userCountriesData: Array<ICountriesData> = [];
let phonePrefixs: Array<string> = [];
let tempIds = [];

Object.values(userCountries).forEach((item) => {
  for (let _item of item) {
    // @ts-ignore
    if (!tempIds.includes(_item.commonName)) {
      _item.telephoneCode = `+${_item.telephoneCode}`;
      phonePrefixs.push(`${_item.telephoneCode}`);
      userCountriesData.push(_item);
      // @ts-ignore
      tempIds.push(_item.commonName);
    }
  }
});
phonePrefixs = [...Array.from(new Set(phonePrefixs))];
export { userCountriesData, phonePrefixs };
